// components
import PageHeader from "@layout/PageHeader";
import TournamentsTabs from "@widgets/TournamentsTabs";
import GamesCalendar from "@widgets/GamesCalendar";
import EsportsStanding from "@widgets/EsportsStanding";
import ScheduleList from "@ui/ScheduleList";

// hooks
import { useState } from "react";
import useMeasure from "react-use-measure";

// constants
import { GROUPS_OPTIONS } from "@constants/selection_options";
import LatestNews from "@widgets/LatestNews";
import NewsPost from "@widgets/NewsPost";

// assets
import rightArrow from "@assets/play-arrow-red.svg";
import webBanner from "@assets/stad_dark.webp";
import featureVideo from "@assets/match.mp4";

const labels = [
	{
		name: "Gaming",
	},
	{
		name: "Video Reviews",
	},
	{
		name: "Previews",
	},
	{
		name: "Movie Reviews",
	},
	{
		name: "Movie News",
	},
	{
		name: "Critic",
	},
	{
		name: "Ratings",
	},
	{
		name: "Funtendo",
	},
];

const News = () => {
	const [ref] = useMeasure();
	const [group, setGroup] = useState(GROUPS_OPTIONS[0].value);
	return (
		<>
			<PageHeader title="News" />
			<div className="w-100 d-flex align-items-center justify-content-center main-header-tabs">
				<TournamentsTabs />
			</div>
			<div className="w-100">
				<ScheduleList
					options={GROUPS_OPTIONS}
					setActive={setGroup}
					innerRef={ref}
				/>
			</div>
			<div className="row align-items-start g-3">
				<div className="col-md-9 col-sm-12 mmb-3">
					<NewsPost />
				</div>
				<div className="col-md-3 col-sm-12 news-right-col">
					<div className="card mb-4 h-auto">
						<div className="card-body">
							<h3 className="mb-3">Categories</h3>
							<ul className="category-link d-flex flex-column row-gap-2">
								<li>
									<a
										href="/news"
										className="d-inline-flex align-items-center gap-2"
									>
										<img src={rightArrow} alt="rightArrow" width="20" />
										Gaming News
									</a>
								</li>
								<li>
									<a
										href="/news"
										className="d-inline-flex align-items-center gap-2"
									>
										<img src={rightArrow} alt="rightArrow" width="20" />
										Tech
									</a>
								</li>
								<li>
									<a
										href="/news"
										className="d-inline-flex align-items-center gap-2"
									>
										<img src={rightArrow} alt="rightArrow" width="20" />
										Console
									</a>
								</li>
								<li>
									<a
										href="/news"
										className="d-inline-flex align-items-center gap-2"
									>
										<img src={rightArrow} alt="rightArrow" width="20" />
										Gadgets
									</a>
								</li>
								<li>
									<a
										href="/news"
										className="d-inline-flex align-items-center gap-2"
									>
										<img src={rightArrow} alt="rightArrow" width="20" />
										Assets
									</a>
								</li>
								<li>
									<a
										href="/news"
										className="d-inline-flex align-items-center gap-2"
									>
										<img src={rightArrow} alt="rightArrow" width="20" />
										Playoffs
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="w-100 mb-4">
						<GamesCalendar />
					</div>
					<div className="w-100 mb-4">
						<EsportsStanding />
					</div>
					<div className="card mb-4 h-auto">
						<div className="card-body">
							<div className="news-banner-img overflow-hidden rounded">
								<img
									src={webBanner}
									alt="webBanner"
									className="w-100 h-100 object-fit-cover"
								/>
							</div>
						</div>
					</div>
					<div className="card mb-4 h-auto">
						<div className="card-body">
							<h3 className="mb-3">Featured Video</h3>
							<div className="news-banner-video no-aspect-ratio overflow-hidden rounded">
								{/* <video
									src={featureVideo}
									className="w-100 h-100 object-fit-cover"
								></video> */}
								<iframe className="w-100"
									src="https://www.youtube.com/embed/JlaMgHLiA-c?autoplay=0&modestbranding=1&controls=1"
									title="FNC vs. GAM | MSI 2024 | Fnatic vs. GAM Esports | GAME 1"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									referrerpolicy="strict-origin-when-cross-origin"
									allowfullscreen
								></iframe>
							</div>
						</div>
					</div>
					<div className="card mb-4 h-auto">
						<div className="card-body">
							<h3 className="mb-3">Pixel Tags</h3>
							<div className="d-flex flex-wrap align-items-center gap-2">
								{labels.map((label, index) => (
									<button className="btn btn--outlined btn--sm rounded">{label.name}</button>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default News;
