// assets
import img1 from "@assets/t-shirt-01.png";
import img2 from "@assets/t-shirt-02.png";
import img3 from "@assets/t-shirt-03.png";
import img4 from "@assets/t-shirt-04.png";

const MERCHANDISING_LIST = [
	{
		assetImg: img1,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img4,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img2,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img3,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img1,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img2,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img3,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img4,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img1,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img3,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img2,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img3,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img4,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img1,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
	{
		assetImg: img3,
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
		team: "MSI League",
		type: "Tshirt/jacket",
		color: "Black",
		fabric: "100% Cotton",
		year: 2024,
	},
];

export default MERCHANDISING_LIST