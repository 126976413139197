const LINKS = [
	{
		title: "Dashboard",
		icon: "space_dashboard",
		pages: [
			{
				title: "Frank Esports Team",
				path: "/",
			},
			{
				title: "News",
				path: "/news",
			},
			{
				title: "Posts",
				path: "/posts",
			},
		],
	},
	{
		title: "Shop",
		icon: "shopping_cart",
		pages: [
			{
				title: "What's New",
				path: "/shop",
			},
			{
				title: "Tickets",
				path: "/tickets",
			},
			{
				title: "Digital",
				path: "/digital-items",
			},
			{
				title: "Merchandising",
				path: "/merchandising",
			},
			{
				title: "Physical goods",
				path: "/physical-goods",
			},
			{
				title: "Content",
				path: "/coming-soon",
			},
		],
	},
	{
		title: "Play",
		icon: "stadia_controller",
		pages: [
			{
				title: "Tournaments",
				path: "/tournaments-list",
			},
			{
				title: "Matches",
				path: "/league-matches",
			},
			{
				title: "Standings",
				path: "/league-standings",
			},
			{
				title: "Polls",
				path: "/league-polls",
			},
			{
				title: "Team Profiles",
				path: "/league-teams",
			},
			{
				title: "Tokenomics",
				path: "/wallet",
			},
		],
	},
	/*--{
		title: "Tokenomics",
		icon: "dollar",
		pages: [
			{
				title: "Create",
				path: "/coming-soon",
			},
			{
				title: "Live Assets",
				path: "/coming-soon",
			},
			{
				title: "Live Contracts",
				path: "/coming-soon",
			},
			{
				title: "Wallets",
				path: "/coming-soon",
			},
			{
				title: "Invoice",
				path: "/coming-soon",
			},
		],
	},
	{
		title: "Teams",
		icon: "users",
		pages: [
			{
				title: "Members",
				path: "/coming-soon",
			},
		],
	},
	{
		title: "News",
		icon: "star-regular",
		pages: [
			{
				title: "What's New",
				path: "/news",
			},
		],
	},
	{
		title: "Posts",
		icon: "book-regular",
		pages: [
			{
				title: "Blog",
				path: "/posts",
			},
			{
				title: "Videos",
				path: "/posts",
			},
		],
	},--*/
	{
		title: "Profile",
		icon: "person",
		pages: [
			{
				title: "User",
				path: "/user-profile",
			},
			{
				title: "Team",
				path: "/userteam-list",
			},
			{
				title: "Invtiations",
				path: "/invitations-list",
			},
		],
	},
];

export default LINKS;
