// components
import PageHeader from "@layout/PageHeader";
import PopularPosts from "@widgets/PopularPosts";
import NewsSlider from "@components/NewsSlider";
import CommentBox from "@components/CommentBox";

// assets
import postBg from "@assets/post-bg.png";
import webBanner from "@assets/stad_dark.webp";
import featureVideo from "@assets/match.mp4";
import userImg from "@assets/avatars/1.webp";

const labels = [
	{
		name: "Gaming",
	},
	{
		name: "Video Reviews",
	},
	{
		name: "Previews",
	},
	{
		name: "Movie Reviews",
	},
	{
		name: "Movie News",
	},
	{
		name: "Critic",
	},
	{
		name: "Ratings",
	},
	{
		name: "Funtendo",
	},
];

const categories = [
	{
		name: "Gaming",
	},
	{
		name: "Video Reviews",
	},
	{
		name: "Previews",
	},
	{
		name: "Movie Reviews",
	},
	{
		name: "Movie News",
	},
	{
		name: "Critic",
	},
	{
		name: "Ratings",
	},
	{
		name: "Funtendo",
	},
];

const Posts = () => {
	return (
		<>
			<PageHeader title="Posts" />
			<div className="row align-items-start g-3">
				<div className="col-md-9 col-sm-12 mmb-3">
					<div className="post-bg-banner w-100 overflow-hidden mb-4">
						<img
							src={postBg}
							alt="PostBanner"
							className="w-100 h-100 object-fit-cover"
						/>
					</div>
					<div className="card mb-4 h-auto">
						<div className="card-body">
							<label className="badge badge-green mb-4">Gaming News</label>
							<h1 className="mb-4">
								The "Clash of Eternity" new game was just released
							</h1>
							<div className="d-flex align-items-center flex-wrap gap-2 mb-4">
								<div className="avatar-xs rounded-circle overflow-hidden">
									<img
										src={userImg}
										alt="userImg"
										className="w-100 h-100 object-fit-cover"
									/>
								</div>
								<p className="text-grey">
									By <span className="text-accent">Dexter</span> | December
									15th, 2018 | <span className="text-accent">174 Comments</span>
								</p>
							</div>
							<h3 className="mb-4">
								The new game from the world famous "Eternity Studio" is back
								with a new adventure game with a lot of classic and puzzle
								elements.
							</h3>
							<div className="inner-content d-flex flex-column gap-3">
								<p>
									Lorem, ipsum dolor sit amet consectetur adipisicing elit.
									Deserunt velit nam rem error recusandae earum, omnis totam
									magnam quae a porro optio, quos assumenda at quidem vel
									blanditiis sed asperiores! Error, consequuntur minus! Magni
									ipsa, libero numquam recusandae eum alias totam fugit
									obcaecati nihil facilis aspernatur labore error consequuntur
									natus accusamus. Voluptatum iusto veritatis maiores velit
									ducimus, veniam consectetur maxime.
								</p>
								<p>
									Magnam repellat id nihil perspiciatis hic rerum, omnis
									blanditiis quod natus, laudantium quos, perferendis possimus
									autem sequi veritatis? Perspiciatis, non deserunt molestiae
									commodi veritatis dolor voluptas deleniti, minus officiis hic
									consequuntur cumque perferendis repellat explicabo possimus
									voluptates. Facere amet beatae, optio provident voluptas
									aspernatur ipsum? Quidem, mollitia rem! Nulla, asperiores
									ratione. Eligendi officiis commodi, recusandae molestiae
									cupiditate enim! Minus et iste assumenda iure cum maiores
									obcaecati temporibus nihil soluta facilis, consequatur
									expedita aperiam quibusdam, magni dolor perspiciatis
									praesentium.
								</p>
							</div>
						</div>
					</div>
					<div className="card mb-4 h-auto">
						<div className="card-body">
							<div className="d-flex flex-wrap gap-2">
								{categories.map((category, index) => (
									<button className="btn btn--outlined btn--sm rounded">
										{category.name}
									</button>
								))}
							</div>
						</div>
					</div>
					<div className="mb-4">
						<h3 className="mb-2">Related News</h3>
						<NewsSlider />
					</div>
					<div className="comment-form mb-4">
						<h3 className="mb-3">Related News</h3>
						<div className="row g-3">
							<div className="col-sm-6">
								<label className="form-label mb-1">Your Name</label>
								<input
									type="text"
									className="field"
									placeholder="Enter your name here"
								/>
							</div>
							<div className="col-sm-6">
								<label className="form-label mb-1">Your Email</label>
								<input
									type="text"
									className="field"
									placeholder="Enter your email here"
								/>
							</div>
							<div className="col-sm-12">
								<label className="form-label mb-1">Your Comment</label>
								<textarea
									className="field"
									placeholder="Write your comment here"
								></textarea>
							</div>
							<div className="col-sm-12 text-end">
								<button className="btn btn--primary">Post Your Comment</button>
							</div>
						</div>
					</div>
					<div className="comments-section">
						<h3 className="mb-2">Comments (174)</h3>
						<CommentBox />
					</div>
				</div>
				<div className="col-md-3 col-sm-12">
					<div className="Popular-Posts-Wrapper mb-4">
						<PopularPosts />
					</div>
					<div className="card mb-4 h-auto">
						<div className="card-body">
							<div className="news-banner-img overflow-hidden rounded">
								<img
									src={webBanner}
									alt="webBanner"
									className="w-100 h-100 object-fit-cover"
								/>
							</div>
						</div>
					</div>
					<div className="card mb-4 h-auto">
						<div className="card-body">
							<h3 className="mb-3">Featured Video</h3>
							<div className="news-banner-video no-aspect-ratio overflow-hidden rounded">
								{/* <video
									src={featureVideo}
									className="w-100 h-100 object-fit-cover"
								></video> */}
								<iframe className="w-100"
									src="https://www.youtube.com/embed/JlaMgHLiA-c?autoplay=0&modestbranding=1&controls=1"
									title="FNC vs. GAM | MSI 2024 | Fnatic vs. GAM Esports | GAME 1"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									referrerpolicy="strict-origin-when-cross-origin"
									allowfullscreen
								></iframe>
							</div>
						</div>
					</div>
					<div className="card mb-4 h-auto">
						<div className="card-body">
							<h3 className="mb-3">Pixel Tags</h3>
							<div className="d-flex flex-wrap align-items-center gap-2">
								{labels.map((label, index) => (
									<button className="btn btn--outlined btn--sm rounded">{label.name}</button>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Posts;
