// components
import Spring from '@components/Spring';
import ScrollContainer from '@components/ScrollContainer';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { Tabs } from '@mui/base/Tabs';
import TabButton from '@ui/TabButton';

// hooks
import useMeasure from 'react-use-measure';
import { useState, useEffect, useRef } from 'react';


const TournamentsTabs = () => {
    const [activeTab, setActiveTab] = useState('MSI');
    const [ref, { height }] = useMeasure();
    const trackRef = useRef(null);

    useEffect(() => {
        trackRef.current && trackRef.current.scrollTo(0, 0);
    }, [activeTab]);

    return (
        <Spring className="card w-100 h-auto Tournaments-Tabs-Card">
            <Tabs className="w-100" value={activeTab}>
                <div className="card-padded" ref={ref}>
                    <TabsList className="Tournaments-Tabs tab-nav">
                        <TabButton title="CN"
                            onClick={() => setActiveTab('CN')}
                            active={activeTab === 'CN'} />
                        <TabButton title="EMEA"
                            onClick={() => setActiveTab('EMEA')}
                            active={activeTab === 'EMEA'} />
                        <TabButton title="KR"
                            onClick={() => setActiveTab('KR')}
                            active={activeTab === 'KR'} />
                        <TabButton title="NA"
                            onClick={() => setActiveTab('NA')}
                            active={activeTab === 'NA'} />
                        <TabButton title="APAC"
                            onClick={() => setActiveTab('APAC')}
                            active={activeTab === 'APAC'} />
                        <TabButton title="VN"
                            onClick={() => setActiveTab('VN')}
                            active={activeTab === 'VN'} />
                        <TabButton title="BR"
                            onClick={() => setActiveTab('BR')}
                            active={activeTab === 'BR'} />
                        <TabButton title="LAT"
                            onClick={() => setActiveTab('LAT')}
                            active={activeTab === 'LAT'} />
                        <TabButton title="MSI"
                            onClick={() => setActiveTab('MSI')}
                            active={activeTab === 'MSI'} />
                    </TabsList>
                </div>
                <ScrollContainer height={height}>
                    <div className="track" style={{ padding: '0 var(--card-padding)' }} ref={trackRef}>
                        <TabPanel value="CN" onClick={() => setActiveTab('CN')}>
                        </TabPanel>
                        <TabPanel value="EMEA" onClick={() => setActiveTab('EMEA')}>
                        </TabPanel>
                        <TabPanel value="KR" onClick={() => setActiveTab('KR')}>
                        </TabPanel>
                        <TabPanel value="NA" onClick={() => setActiveTab('NA')}>
                        </TabPanel>
                        <TabPanel value="APAC" onClick={() => setActiveTab('APAC')}>
                        </TabPanel>
                        <TabPanel value="VN" onClick={() => setActiveTab('VN')}>
                        </TabPanel>
                        <TabPanel value="BR" onClick={() => setActiveTab('BR')}>
                        </TabPanel>
                        <TabPanel value="LAT" onClick={() => setActiveTab('LAT')}>
                        </TabPanel>
                        <TabPanel value="MSI" onClick={() => setActiveTab('MSI')}>
                        </TabPanel>
                    </div>
                </ScrollContainer>
            </Tabs>
        </Spring>
    )
}

export default TournamentsTabs