// styling
import styled from "styled-components/macro";
import { css } from "styled-components";
import theme from "styled-theming";

// components
import { Swiper, SwiperSlide } from "swiper/react";

// hooks
import { useEffect, useState } from "react";
import { useThemeProvider } from "@contexts/themeContext";

// utils
import PropTypes from "prop-types";

// constants
import MATCHES from "@constants/matches";

const prevButton = css`
	padding-left: 10px;
	background: rgba(0,0,0,0.2);
	border-radius: 4px 0 0 0;
`;

const nextButton = css`
	padding-right: 10px;
	background: rgba(0,0,0,0.2);
	border-radius: 0 4px 0 0;
`;

const StyledSelectionList = styled.div`
	display: flex;
	gap: 20px;
	margin-bottom: 20px;
	box-shadow: 0 1px 8px rgba(110, 110, 110, 0.1);
	background: ${theme("theme", {
		light: "var(--widget)",
		dark: "var(--color-blue5)",
	})};
	padding: 0px 60px;
	position: relative;

	.swiper {
		padding: 20px 0;
	}

	.swiper-slide {
		width: fit-content;
	}

	.nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;

		button {
			width: 30px;
			height: 100%;
			display: flex;
			align-items: center;
			color: #8ea2ab;
		}

		&.ltr button {
			&:first-child {
				${prevButton};
				justify-content: flex-start;
			}

			&:last-child {
				${nextButton};
				justify-content: flex-end;
			}
		}

		&.rtl button {
			&:first-child {
				${nextButton};
				justify-content: flex-start;
			}

			&:last-child {
				${prevButton};
				justify-content: flex-end;
			}
		}
	}
`;


const ScheduleList = ({ active, setActive, options, innerRef }) => {
	const [swiper, setSwiper] = useState(null);
	const { direction } = useThemeProvider();

	const handlePrev = () => {
		if (swiper && swiper.realIndex >= 0 && options && options.length > 0) {
			swiper.slidePrev();
			const activeValue =
				options[swiper.realIndex]?.value || options[swiper.realIndex];
			setActive(activeValue);
		}
	};

	const handleNext = () => {
		if (swiper && swiper.realIndex >= 0 && options && options.length > 0) {
			swiper.slideNext();
			const activeValue =
				options[swiper.realIndex]?.value || options[swiper.realIndex];
			setActive(activeValue);
		}
	};

	useEffect(() => {
		if (swiper) {
			swiper.changeLanguageDirection(direction);
			swiper.update();
		}
	}, [swiper, direction]);

	return (
		<StyledSelectionList ref={innerRef}>
			<div className={`nav ${direction}`}>
				<button aria-label="Previous" onClick={handlePrev}>
					<i className="icon icon-chevron-left" />
				</button>
				<button aria-label="Next" onClick={handleNext}>
					<i className="icon icon-chevron-right" />
				</button>
			</div>
			<Swiper
				className="selection-list w-100 h-100"
				onSwiper={setSwiper}
				slidesPerView={9}
				spaceBetween={6}
				loop={true}
				centeredSlides={true}
				breakpoints={{
					// when window width is >= 0px
					10: {
						slidesPerView: 2,
						spaceBetween: 6
					},
					// when window width is >= 320px
					320: {
						slidesPerView: 3,
						spaceBetween: 6
					},
					// when window width is >= 480px
					480: {
						slidesPerView: 3,
						spaceBetween: 6
					},
					// when window width is >= 640px
					640: {
						slidesPerView: 4,
						spaceBetween: 6
					},
					// when window width is >= 768px
					768: {
						slidesPerView: 4,
						spaceBetween: 6
					},
					// when window width is >= 1024px
					900: {
						slidesPerView: 7,
						spaceBetween: 6
					},
				}}
			>
				{MATCHES.map((match, index) => (
					<SwiperSlide key={index}>
						<div className="d-flex flex-column">
							<h4 className="d-flex match-day">{match.day}</h4>
							<h5 className="d-flex flex-column my-1">
								<div className="d-flex align-items-center">
									<img
										className="match-avatar"
										src={match.iconteam1}
										alt="Team Logo"
									/>
									<span className="match-team-name">{match.team1}</span>
								</div>
								<div className="d-flex align-items-center">
									<img
										className="match-avatar"
										src={match.iconteam2}
										alt="Team Logo"
									/>
									<span className="match-team-name">{match.team2}</span>
								</div>
							</h5>
							<h6 className="d-flex match-time">{match.time}</h6>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</StyledSelectionList>
	);
};

ScheduleList.propTypes = {
	active: PropTypes.string,
	setActive: PropTypes.func,
	options: PropTypes.array,
	innerRef: PropTypes.any,
};

export default ScheduleList;
