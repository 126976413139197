// components
import Spring from "@components/Spring";
import ScrollContainer from "@components/ScrollContainer";

const videos = [
	{
		title: "Article",
		matchtitle: "BMing in Finals? | Kia Mic Check | 2024 LEC Spring Finals",
		imagethumb: "https://i.ytimg.com/vi/4PvFP4zQ-FA/sddefault.jpg",
	},
	{
		title: "Article",
		matchtitle: "Are Lane Swaps Back? | LEC Playbook",
		imagethumb: "https://i.ytimg.com/vi/nNuHkjgt0pA/sddefault.jpg",
	},
	{
		title: "Article",
		matchtitle: "2024 LEC Season Finals Roadshow",
		imagethumb:
			"https://images.contentstack.io/v3/assets/bltad9188aa9a70543a/blt2ce97e45a11b6b43/6618d30d2ecd8813586081b3/Article_Header.jpg?width=3200&height=1800",
	},
	{
		title: "Article",
		matchtitle: "The Final Boss | EUphoria | 2024 LEC EP11",
		imagethumb: "https://i.ytimg.com/vi/ibON_mLvIpc/sddefault.jpg",
	},
	{
		title: "Article",
		matchtitle: "Champions Quiz | LEC Pop Quiz | 2024 Spring",
		imagethumb: "https://i.ytimg.com/vi/_v5F6jSd-f0/sddefault.jpg",
	},
];

const LatestNews = () => {
	return (
		<Spring className="card h-auto">
			<h3 className="card_header">Latest News</h3>
			<div className="main-video-wrapper main-news-wrapper dashboard-latest-news-section">
				<div className="d-flex flex-column items-center video-highlight-card">
					<img
						className="video-avatar main-news-card-avatar"
						src="https://images.contentstack.io/v3/assets/bltad9188aa9a70543a/blta862133e73c81556/660dd44ab1b99a962c310c1c/LoL_MSIE24_007_DrawShowExplainer_ArticleHeader.jpg?width=3200&height=1800"
						alt="Team Logo"
					/>
					<div className="d-flex flex-column video-highlight-card-right main-news-card-right">
						<span className="match-team-name title">Article</span>
						<span className="match-team-name match-title">
							Learn More about the MSI Draw Show!
						</span>
					</div>
				</div>
			</div>
			<ScrollContainer height={0}>
				<div className="track d-flex flex-column g-20 main-video-wrapper">
					{videos.map((video, index) => (
						<div className="d-flex align-items-center video-highlight-card">
							<div className="video-avatar">
								<img
									className="w-100 h-100 object-fit-cover"
									src={video.imagethumb}
									alt="Team Logo"
								/>
							</div>
							<div className="d-flex flex-column video-highlight-card-right">
								<span className="match-team-name title">{video.title}</span>
								<span className="match-team-name match-title">
									{video.matchtitle}
								</span>
							</div>
						</div>
					))}
					<a href="/news" className="d-inline-flex">
						<button className="btn btn--primary w-100">View All News</button>
					</a>
				</div>
			</ScrollContainer>
		</Spring>
	);
};

export default LatestNews;
