// styling
import styled from "styled-components/macro";
import styles from "./styles.module.scss";

// components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperControls from "@ui/SwiperControls";

// hooks
import { useEffect, useState } from "react";
import { useThemeProvider } from "@contexts/themeContext";

// utils
import PropTypes from "prop-types";

// assets
import img1 from "@assets/products/goods-1.jpg";
import img2 from "@assets/products/goods-2.jpg";
import img3 from "@assets/products/goods-3.jpg";
import img4 from "@assets/products/goods-4.jpg";
import img5 from "@assets/products/goods-5.jpg";
import img6 from "@assets/products/goods-6.jpg";
import img7 from "@assets/products/goods-7.jpg";
import img8 from "@assets/products/goods-8.jpg";
import CustomRating from "@ui/CustomRating";

const ProductsList = styled.div`
	display: flex;
	position: relative;

	.swiper {
		padding: 10px 0;
	}
`;

const products = [
	{
		assetImg: img1,
		name: "Ryzen Gaming Mouse",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img2,
		name: "Mouse Pad Plus X",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img3,
		name: "Custom Gaming Console",
		category: "Gamingstation",
		price: 250.0,
	},
	{
		assetImg: img4,
		name: "Rock Gaming Heads",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img5,
		name: "Gameboy ADV",
		category: "Gamingstation",
		price: 250.0,
	},
	{
		assetImg: img6,
		name: "Pro Chair R",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img7,
		name: "Xbox Held",
		category: "Gamingstation",
		price: 250.0,
	},
	{
		assetImg: img8,
		name: "Mech Keyboard White - 200hz",
		category: "Pro Gaming",
		price: 250.0,
	},
];

const Products = ({ active, setActive, options, innerRef }) => {
	const [swiper, setSwiper] = useState(null);
	const { direction } = useThemeProvider();

	useEffect(() => {
		if (swiper) {
			swiper.changeLanguageDirection(direction);
			swiper.update();
		}
	}, [swiper, direction]);

	return (
		<ProductsList ref={innerRef}>
			<Swiper
				className="h-100"
				onSwiper={setSwiper}
				dir={direction}
				slidesPerView={1}
				spaceBetween={24}
				loop={true}
				breakpoints={{
					768: {
						slidesPerView: 3,
					},
					1200: {
						slidesPerView: 5,
					},
				}}
			>
				{products.map((product, index) => (
					<SwiperSlide key={index}>
						<div className="card position-relative">
							<div className="card-body">
								<div
									className={`${styles.card_img} card-img rounded overflow-hidden mb-3`}
								>
									<img
										src={product.assetImg}
										alt={product.title}
										className="w-auto h-100 object-fit-contain mx-auto"
									/>
								</div>
								<h3 className="text-break text-uppercase mb-1">
									{product.name}
								</h3>
								<div className="d-flex align-items-center gap-2 mb-3">
									<p>{product.category}</p>
									<CustomRating />
								</div>
								<div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
									<h3>$ {product.price}</h3>
									<button className="btn btn--primary btn--sm">
										Add to Cart
									</button>
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			<SwiperControls swiper={swiper} />
		</ProductsList>
	);
};

Products.propTypes = {
	active: PropTypes.string,
	setActive: PropTypes.func,
	options: PropTypes.array,
	innerRef: PropTypes.any,
};

export default Products;
