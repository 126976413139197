// styling
import styles from "./styles.module.scss";

// assets
import img1 from "@assets/table-team-profile-01.png";
import img2 from "@assets/table-team-profile-02.png";
import img3 from "@assets/table-team-profile-03.png";

const data = [
	{
		date: "09-05-2023",
		pi: "5-6",
		event: "BlizzCon",
		lastResult: "0:1",
		teamProfile: img1,
		teamName: "Fox Academy",
		roster: "Licorice, Atlantis, Palafox, Reven, CoreJJ",
	},
	{
		date: "11-02-2023",
		pi: "3-4",
		event: "DreamHack",
		lastResult: "0:1",
		teamProfile: img2,
		teamName: "TSM Academy",
		roster: "Licorice, Atlantis, Spirax, Palafox, Reven, CoreJJ",
	},
	{
		date: "17-11-2022",
		pi: "9",
		event: "BlizzCon",
		lastResult: "6:12",
		teamProfile: img1,
		teamName: "Fox Academy",
		roster: "Solo, Atlantis, Curry, Reven, CoreJJ",
	},
	{
		date: "17-06-2022",
		pi: "4",
		event: "Int. Esports Federation",
		lastResult: "2:3",
		teamProfile: img2,
		teamName: "TSM Academy",
		roster: "Licorice, Atlantis, Palafox, Reven, CoreJJ",
	},
	{
		date: "18-04-2022",
		pi: "2",
		event: "BlizzCon",
		lastResult: "30:15",
		teamProfile: img3,
		teamName: "FlyQuest",
		roster: "Huni, Atlantis, Palafox, Reven, CoreJJ",
	},
	{
		date: "13-09-2021",
		pi: "4",
		event: "DreamHack",
		lastResult: "12:6",
		teamProfile: img1,
		teamName: "Fox Academy",
		roster: "Licorice, Atlantis, Palafox, Reven, CoreJJ",
	},
	{
		date: "27-08-2012",
		pi: "5-7",
		event: "BlizzCon",
		lastResult: "10:8",
		teamProfile: img1,
		teamName: "Fox Academy",
		roster: "Broken Blade, Atlantis, Spica, Doublelift, CoreJJ",
	},
	{
		date: "25-12-2020",
		pi: "4",
		event: "Int. Esports Federation",
		lastResult: "1:6",
		teamProfile: img3,
		teamName: "FlyQuest",
		roster: "Licorice, Atlantis, Palafox, Reven, CoreJJ",
	},
	{
		date: "29-05-2020",
		pi: "1",
		event: "BlizzCon",
		lastResult: "0:6",
		teamProfile: img2,
		teamName: "TSM Academy",
		roster: "Treatz, Atlantis, Palafox, Zven, CoreJJ",
	},
	{
		date: "26-08-2019",
		pi: "9",
		event: "DreamHack",
		lastResult: "9:9",
		teamProfile: img2,
		teamName: "TSM Academy",
		roster: "Reven, Tactical, Palafox, Frosty, CoreJJ",
	},
];

const TournamentResultProfile = () => {
	return (
		<>
			<div className="w-100 card card-padded">
				<div className="table-responsive tournament-result">
					<table className="table m-0">
						<thead>
							<tr>
								<th className="sorting">Date</th>
								<th className="sorting">PI</th>
								<th className="sorting">Event</th>
								<th className="sorting">Last Result</th>
								<th className="sorting">Team</th>
								<th className="sorting">Roster</th>
							</tr>
						</thead>
						<tbody>
							{data.map((item, index) => (
								<tr key={index}>
									<td className="py-2">{item.date}</td>
									<td className="py-2">{item.pi}</td>
									<td className="text-accent py-2">{item.event}</td>
									<td className="py-2">
										<div className="d-flex align-items-center justify-content-between gap-2">
											{item.lastResult}
											<i className="material-symbols-outlined fs-14 d-inline-flex">
												info
											</i>
										</div>
									</td>
									<td className="py-2">
										<div className="d-flex align-items-center gap-1 w-100">
											<img
												src={item.teamProfile}
												alt="teamProfileImg"
												className="avatar-sm flex-shrink-0"
											/>
											<span className="d-inline-flex">{item.teamName}</span>
										</div>
									</td>
									<td className="py-2">{item.roster}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default TournamentResultProfile;
