// GA
import ReactGA from "react-ga4";

// utils
import { lazy, Suspense } from "react";

// styles
import ThemeStyles from "@styles/theme";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";

// libs styles
import "react-toastify/dist/ReactToastify.min.css";
import "react-grid-layout/css/styles.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
// import "material-icons/iconfont/material-icons.css";

// fonts
import "@fonts/icomoon/icomoon.woff";

// contexts
import { SidebarProvider } from "@contexts/sidebarContext";
import { ThemeProvider } from "styled-components";
import { TournamentProvider } from "@contexts/tournamentContext";

// hooks
import { useThemeProvider } from "@contexts/themeContext";
import { useEffect, useRef } from "react";
import { useWindowSize } from "react-use";
import useAuthRoute from "@hooks/useAuthRoute";
import { Navigate,useNavigate } from 'react-router-dom';

// utils
import { StyleSheetManager } from "styled-components";
import {
	ThemeProvider as MuiThemeProvider,
	createTheme,
} from "@mui/material/styles";
import { preventDefault } from "@utils/helpers";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// components
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoadingScreen from "@components/LoadingScreen";
import Sidebar from "@layout/Sidebar";
import BottomNav from "@layout/BottomNav";
import Navbar from "@layout/Navbar";
import ShoppingCart from "@widgets/ShoppingCart";
import ScrollToTop from "@components/ScrollToTop";
import Shop from "@pages/Shop";
import Digital from "@pages/Digital";
import DigitalGoodsDetails from "@pages/DigitalGoodsDetails";
import Merchandising from "@pages/Merchandising";
import MerchandisingDetails from "@pages/MerchandisingDetails";
import PhysicalGoods from "@pages/PhysicalGoods";
import PhysicalGoodsDetails from "@pages/PhysicalGoodsDetails";
import Content from "@pages/Content";
import Posts from "@pages/Posts";
import News from "@pages/News";
import UserProfile from "@pages/UserProfile";
import Wallet from "@pages/Wallet";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MSAL_CONFIG } from "config/AuthConfig";
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from "@azure/msal-browser";
import AdminSidebar from "@layout/AdminSidebar";
import InvitationList from "@pages/InvitationList";
import ProtectedRoute from "@components/ProtectedRoute/ProtectedRoute";

// pages
const ClubSummary = lazy(() => import("@pages/ClubSummary"));
const GameSummary = lazy(() => import("@pages/GameSummary"));
const Championships = lazy(() => import("@pages/Championships"));
const LeagueOverview = lazy(() => import("@pages/LeagueOverview"));
const FansCommunity = lazy(() => import("@pages/FansCommunity"));
const Statistics = lazy(() => import("@pages/Statistics"));
const PageNotFound = lazy(() => import("@pages/PageNotFound"));
const MatchSummary = lazy(() => import("@pages/MatchSummary"));
const MatchOverview = lazy(() => import("@pages/MatchOverview"));
const PlayerProfile = lazy(() => import("@pages/PlayerProfile"));
const Schedule = lazy(() => import("@pages/Schedule"));
const Tickets = lazy(() => import("@pages/Tickets"));
const TicketDetails = lazy(() => import("@pages/TicketDetails"));
const FootballStore = lazy(() => import("@pages/FootballStore"));
const BrandStore = lazy(() => import("@pages/BrandStore"));
const Product = lazy(() => import("@pages/Product"));
const Login = lazy(() => import("@pages/Login"));
const SignUp = lazy(() => import("@pages/SignUp"));
const ForgotPassword = lazy(() => import("@pages/ForgotPassword"));
const Settings = lazy(() => import("@pages/Settings"));
const ComingSoon = lazy(() => import("@pages/ComingSoon"));
const LeagueTournaments = lazy(() => import("@pages/LeagueTournaments"));
const LeagueMatches = lazy(() => import("@pages/LeagueMatches"));
const LeagueStandings = lazy(() => import("@pages/LeagueStandings"));
const LeaguePolls = lazy(() => import("@pages/LeaguePolls"));
const LeagueTeams = lazy(() => import("@pages/LeagueTeams"));
const LeagueTeamProfile = lazy(() => import("@pages/LeagueTeamProfile"));
const CreateTeam = lazy(() => import("@pages/CreateTeam"));
const CreateMember = lazy(() => import("@pages/CreateMember"));
const UserTeamProfile = lazy(() => import("@pages/UserTeamProfile"));
const CreateTournament = lazy(() => import('@pages/CreateTournament'));
const NotAuthorized = lazy(() => import('@pages/NotAuthorized'));
const TournamentsList = lazy(() => import('@pages/TournamentsList')); 
const EditTeamProfile = lazy(() => import("@pages/EditTeamProfile"));
const EditMember = lazy(() => import("@pages/EditMember"));
const ViewMembers = lazy(() => import("@pages/ViewMembers"));
const UserTeamList = lazy(() => import("@pages/UserTeamList"));
const AdminNews = lazy(() => import("@pages/Admin/AdminNews"));
const AdminPosts = lazy(() => import("@pages/Admin/AdminPosts"));
const AdminCreate = lazy(() => import("@pages/Admin/AdminCreate"));
const AdminList = lazy(() => import("@pages/Admin/AdminList"));
const AdminPrizes = lazy(() => import("@pages/Admin/AdminPrizes"));
const AdminTeams = lazy(() => import("@pages/Admin/AdminTeams"));
const AdminUser = lazy(() => import("@pages/Admin/AdminUser"));



const App = ({ msalInstance }) => {
	const appRef = useRef(null);
	const { theme, direction } = useThemeProvider();
	const { width } = useWindowSize();
	const isAuthRoute = useAuthRoute();

	// Google Analytics init
	const gaKey = process.env.REACT_APP_PUBLIC_GA;
	gaKey && ReactGA.initialize(gaKey);

	// auto RTL support for Material-UI components and styled-components

	const plugins = direction === "rtl" ? [rtlPlugin] : [];

	const muiTheme = createTheme({
		direction: direction,
	});

	const cacheRtl = createCache({
		key: "css-rtl",
		stylisPlugins: plugins,
	});

	useEffect(() => {
		// scroll to top on route change
		appRef.current && appRef.current.scrollTo(0, 0);

		preventDefault();
	}, []);

	return (
		<MsalProvider instance={msalInstance}>


			<GoogleOAuthProvider clientId="621916765212-ijvoph4b5hi12o48omenjl4eeindg0t5.apps.googleusercontent.com">

				<CacheProvider value={cacheRtl}>
					<MuiThemeProvider theme={muiTheme}>
						<TournamentProvider>
						<SidebarProvider>
							<ThemeProvider theme={{ theme: theme }}>
								<ThemeStyles />
								<ToastContainer
									theme={theme}
									autoClose={2500}
									position={direction === "ltr" ? "top-right" : "top-left"}
								/>
								<StyleSheetManager stylisPlugins={plugins}>
									<div
										className={`app gradient-bg ${isAuthRoute ? "fluid" : ""}`}
										ref={appRef}
									>
										<ScrollToTop />
										{!isAuthRoute && (
											<>
												{/* <AdminSidebar /> */}
												<Sidebar />
												{width < 768 && <Navbar />}
												{width < 768 && <BottomNav />}
											</>
										)}
										<div className="app_container">
											<div className="app_container-content d-flex flex-column flex-1">
												<Suspense fallback={<LoadingScreen />}>
													<Routes>
														<Route path="*" element={<PageNotFound />} />
														<Route path="/" element={<ProtectedRoute><ClubSummary /></ProtectedRoute> } />
														<Route path="/game-summary" element={<ProtectedRoute><GameSummary /> </ProtectedRoute>} />
														<Route
															path="/championships"
															element={<ProtectedRoute><Championships /></ProtectedRoute>}
														/>
														<Route
															path="/league-overview"
															element={<ProtectedRoute><LeagueOverview /></ProtectedRoute>}
														/>
														<Route
															path="/fans-community"
															element={<ProtectedRoute><FansCommunity /></ProtectedRoute>}
														/>
														<Route path="/statistics" element={<Statistics />} />
														<Route
															path="/match-summary"
															element={<ProtectedRoute><MatchSummary /></ProtectedRoute>}
														/>
														<Route
															path="/match-overview"
															element={<ProtectedRoute><MatchOverview /></ProtectedRoute>}
														/>
														<Route
															path="/player-profile"
															element={<ProtectedRoute><PlayerProfile /></ProtectedRoute>}
														/>
														<Route path="/posts" element={<ProtectedRoute><Posts /></ProtectedRoute>} />
														<Route path="/user-profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
														<Route path="/wallet" element={<ProtectedRoute><Wallet /></ProtectedRoute>} />
														<Route path="/news" element={<ProtectedRoute><News /></ProtectedRoute>} />
														<Route path="/schedule" element={<ProtectedRoute><Schedule /></ProtectedRoute>} />
														<Route path="/shop" element={<ProtectedRoute><Shop /></ProtectedRoute>} />
														<Route path="/tickets" element={<ProtectedRoute><Tickets /></ProtectedRoute>} />
														<Route path="/digital-items" element={<ProtectedRoute><Digital /></ProtectedRoute>} />
														<Route
															path="/merchandising"
															element={<ProtectedRoute><Merchandising /></ProtectedRoute>}
														/>
														<Route
															path="/physical-goods"
															element={<ProtectedRoute><PhysicalGoods /></ProtectedRoute>}
														/>
														<Route path="/content" element={<ProtectedRoute><Content /></ProtectedRoute>} />
														<Route
															path="/football-store"
															element={<ProtectedRoute><FootballStore /></ProtectedRoute>}
														/>
														<Route path="/brand-store" element={<ProtectedRoute><BrandStore /></ProtectedRoute>} />
														<Route path="/product" element={<ProtectedRoute><Product /></ProtectedRoute>} />
														<Route path="/login" element={<Login />} />
														<Route path="/sign-up" element={<SignUp />} />
														<Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
														<Route path="/coming-soon" element={<ProtectedRoute><ComingSoon /></ProtectedRoute>} />
														<Route
															path="/league-tournaments/:id"
															element={<ProtectedRoute><LeagueTournaments /></ProtectedRoute>}
														/>
														<Route
															path="/league-matches"
															element={<ProtectedRoute><LeagueMatches /></ProtectedRoute>}
														/>
														<Route
															path="/league-standings"
															element={<ProtectedRoute><LeagueStandings /></ProtectedRoute>}
														/>
														<Route path="/league-polls" element={<ProtectedRoute><LeaguePolls /></ProtectedRoute>} />
														<Route path="/league-teams" element={<ProtectedRoute><LeagueTeams /></ProtectedRoute>} />
														<Route
															path="/league-team-profile"
															element={<ProtectedRoute><LeagueTeamProfile /></ProtectedRoute>}
														/>
														<Route
															path="/physical-goods-details"
															element={<ProtectedRoute><PhysicalGoodsDetails /></ProtectedRoute>}
														/>
														<Route
															path="/digital-goods-details"
															element={<ProtectedRoute><DigitalGoodsDetails /></ProtectedRoute>}
														/>
														<Route
															path="/merchandising-details"
															element={<ProtectedRoute><MerchandisingDetails /></ProtectedRoute>}
														/>
														<Route
															path="/ticket-details"
															element={<ProtectedRoute><TicketDetails /></ProtectedRoute>}
														/>
														<Route
															path="/forgot-password"
															element={<ProtectedRoute><ForgotPassword /></ProtectedRoute>}
														/>
														<Route
															path="/create-tournament"
															element={<ProtectedRoute><CreateTournament /></ProtectedRoute>}
														/>
														<Route
															path="/edit-tournament/:id"
															element={<ProtectedRoute><CreateTournament /></ProtectedRoute>}
														/>
														<Route
															path="/tournaments-list"
															element={<ProtectedRoute><TournamentsList /></ProtectedRoute>}
														/>
														<Route path="/not-authorized" element={<NotAuthorized />} />

														<Route path="/create-team" element={<ProtectedRoute><CreateTeam /></ProtectedRoute>} />
														<Route path="/edit-teamprofile/:teamId" element={<ProtectedRoute><CreateTeam /></ProtectedRoute>} />
														<Route path="/userteam-list" element={<ProtectedRoute><UserTeamList /></ProtectedRoute>} />
														<Route path="/user-team-profile/:teamId" element={<ProtectedRoute><UserTeamProfile /></ProtectedRoute>} />
														
														<Route path="/create-member/:teamId" element={<ProtectedRoute><CreateMember /></ProtectedRoute>} />
														<Route path="/edit-member/:teamId/:memberId" element={<ProtectedRoute><EditMember /></ProtectedRoute>} />
														<Route path="/view-members/:teamId" element={<ProtectedRoute><ViewMembers /></ProtectedRoute>} />
														
														
														<Route path="/admin-news" element={<ProtectedRoute><AdminNews /></ProtectedRoute>} />
														<Route path="/admin-posts" element={<ProtectedRoute><AdminPosts /></ProtectedRoute>} />
														<Route path="/admin-create" element={<ProtectedRoute><AdminCreate /></ProtectedRoute>} />
														<Route path="/admin-list" element={<ProtectedRoute><AdminList /></ProtectedRoute>} />
														<Route path="/admin-prizes" element={<ProtectedRoute><AdminPrizes /></ProtectedRoute>} />
														<Route path="/admin-teams" element={<ProtectedRoute><AdminTeams /></ProtectedRoute>} />
														<Route path="/admin-user" element={<ProtectedRoute><AdminUser /></ProtectedRoute>} />
														<Route path="/invitations-list" element={<ProtectedRoute><InvitationList /></ProtectedRoute>} />
													</Routes>
												</Suspense>
											</div>
											<ShoppingCart isPopup />
										</div>
									</div>
									</StyleSheetManager>
								</ThemeProvider>
							</SidebarProvider>
						</TournamentProvider>
					</MuiThemeProvider>
				</CacheProvider>
			</GoogleOAuthProvider>
		</MsalProvider>
	);
};

export default App;
