import img1 from '@assets/t-shirt-01.png';
import img2 from '@assets/t-shirt-02.png';
import img3 from '@assets/t-shirt-03.png';
import img4 from '@assets/t-shirt-04.png';

const products = [
    {
        id: 'item-1',
        img: img1,
        name: 'Dri-FIT Element Sport T-shirt',
        category: 'Sweatshirts',
        price: 70,
        rating: 5,
    },
    {
        id: 'item-2',
        img: img2,
        name: 'Dri-FIT Challenger Hoodie',
        category: 'Running tights',
        price: 65,
        rating: 5,
    },
    {
        id: 'item-3',
        img: img3,
        name: 'Sportswear Club T-shirt',
        category: 'Jackets',
        price: 90,
        rating: 4,
    },
    {
        id: 'item-4',
        img: img4,
        name: 'Utility Elite Sport T-shirt',
        category: 'Accessories',
        price: 112,
        rating: 5,
    },
    {
        id: 'item-5',
        img: img1,
        name: 'High-Waisted Sport T-shirt',
        category: 'Shorts',
        price: 65,
        rating: 5,
    },
    {
        id: 'item-6',
        img: img2,
        name: 'Oversized French Terry Hoodie',
        category: 'Hoodies',
        price: 95,
        rating: 4,
    },
    {
        id: 'item-7',
        img: img3,
        name: 'Luxe Sport T-shirt',
        category: 'Yoga sets',
        price: 200,
        rating: 4,
    },
    {
        id: 'item-8',
        img: img4,
        name: 'Jordan Flight Sport T-shirt',
        category: 'Accessories',
        price: 65.97,
        rating: 4.5,
    }
]

export default products