const esports_standings = [
    {
        name: 'FNC',
        color: 'light-azure',
        pts: '27-13',
        w: 5,
        d: 1,
        l: 2
    },
    {
        name: 'GEN',
        color: 'red',
        pts: '28-14',
        w: 8,
        d: 0,
        l: 0
    },
    {
        name: 'BLG',
        color: 'salmon',
        pts: '26-11',
        w: 4,
        d: 3,
        l: 1
    },
    {
        name: 'PSG',
        color: 'azure',
        pts: '14-20',
        w: 4,
        d: 2,
        l: 2
    },
    {
        name: 'G2',
        color: 'fb',
        pts: '18-22',
        w: 4,
        d: 2,
        l: 2
    },
    {
        name: 'T1',
        color: 'azure',
        pts: '11-15',
        w: 4,
        d: 2,
        l: 2
    },
    {
        name: 'SDK',
        color: 'fb',
        pts: '11-15',
        w: 4,
        d: 2,
        l: 2
    },
    {
        name: 'CAP',
        color: 'salmon',
        pts: '11-16',
        w: 4,
        d: 2,
        l: 2
    },
    {
        name: 'MN',
        color: 'red',
        pts: '8-16',
        w: 4,
        d: 2,
        l: 2
    }
]

export default esports_standings