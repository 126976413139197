// components
import Spring from "@components/Spring";
import ScrollContainer from "@components/ScrollContainer";
import Pagination from "@components/Pagination";

const videos = [
	{
		title: "Article",
		matchtitle: "BMing in Finals? | Kia Mic Check | 2024 LEC Spring Finals",
		imagethumb: "https://i.ytimg.com/vi/4PvFP4zQ-FA/sddefault.jpg",
	},
	{
		title: "Article",
		matchtitle: "Are Lane Swaps Back? | LEC Playbook",
		imagethumb: "https://i.ytimg.com/vi/nNuHkjgt0pA/sddefault.jpg",
	},
	{
		title: "Article",
		matchtitle: "2024 LEC Season Finals Roadshow",
		imagethumb:
			"https://images.contentstack.io/v3/assets/bltad9188aa9a70543a/blt2ce97e45a11b6b43/6618d30d2ecd8813586081b3/Article_Header.jpg?width=3200&height=1800",
	},
	{
		title: "Article",
		matchtitle: "The Final Boss | EUphoria | 2024 LEC EP11",
		imagethumb: "https://i.ytimg.com/vi/ibON_mLvIpc/sddefault.jpg",
	},
	{
		title: "Article",
		matchtitle: "Champions Quiz | LEC Pop Quiz | 2024 Spring",
		imagethumb: "https://i.ytimg.com/vi/_v5F6jSd-f0/sddefault.jpg",
	},
];

const PopularPosts = () => {
	return (
		<Spring className="card h-auto">
			<h3 className="card_header">Popular Posts</h3>
			{/* <div className="inner-scroll-wrapper" style={{ height: "600px" }}> */}
				<div className="inner-scroll d-flex flex-column g-20 main-video-wrapper">
					{videos.map((video, index) => (
						<div className="row align-items-center video-highlight-card post-card g-3 g-md-4">
							<div className="col-5 col-md-4">
								<div className="video-avatar w-100 h-100">
									<img
										className="w-100 h-100 object-fit-cover"
										src={video.imagethumb}
										alt="Team Logo"
									/>
								</div>
							</div>
							<div className="col-7 col-md-8">
								<div className="d-flex flex-column video-highlight-card-right m-0">
									<span className="title">{video.title}</span>
									<span className="match-title line-clamp line-clamp-2 lh-base">
										{video.matchtitle}
									</span>
								</div>
							</div>
						</div>
					))}
				</div>
			{/* </div> */}
		</Spring>
	);
};

export default PopularPosts;
