const tournamentStaticData = [
    {
      name: "Tournament 1",
      organizers: "Reliance",
      gameTitle: "Fifa",
      startDate: "12/11/2023",
      endDate: "15/12/2023",
      pricePool: "10000000",
      registrationFee: "100",
      platform: { value: 'PC', label: 'PC' },
      country: { value: 'UK', label: 'UK' },
      language: { value: 'Spanish', label: 'Spanish' },
      format: { value: 'Single Elimination', label: 'Single Elimination' },
      players: "15",
      organizerContact: "Pepsi",
      rulesRegulation: "Players should wear all gears before match",
      socialMediaHandles: ["https://www.facebook.com", 'https://www.instagram.com'],
      banner: 'https://images.contentstack.io/v3/assets/bltad9188aa9a70543a/blta862133e73c81556/660dd44ab1b99a962c310c1c/LoL_MSIE24_007_DrawShowExplainer_ArticleHeader.jpg?width=3200&height=1800'
    },
    {
      name: "Tournament 2",
      organizers: "Aramco",
      gameTitle: "Cricket",
      startDate: "12/10/2024",
      endDate: "23/12/2024",
      pricePool: "100000000",
      registrationFee: "120",
      platform: { value: 'Xbox', label: 'Xbox' },
      country: { value: 'USA', label: 'USA' },
      language: { value: 'French', label: 'French' },
      format: { value: 'Double Elimination', label: 'Double Elimination' },
      players: "15",
      organizerContact: "Reliance",
      rulesRegulation: "Players should wear all gears before match",
      socialMediaHandles: ["https://www.facebook.com", 'https://www.instagram.com'],
      banner: 'https://images.contentstack.io/v3/assets/bltad9188aa9a70543a/blta862133e73c81556/660dd44ab1b99a962c310c1c/LoL_MSIE24_007_DrawShowExplainer_ArticleHeader.jpg?width=3200&height=1800'
    }
  ]

export default tournamentStaticData;