import Invitations from "@components/Invitations"
import PageHeader from "@layout/PageHeader"

const InvitationList = () => {
    return (
        <>
            <PageHeader title="Invitation List" />
            <div className="w-100 card card-padded">
                <Invitations />
            </div>
        </>


    )
}

export default InvitationList