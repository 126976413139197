// Digital assets
import dimg1 from "@assets/digital/digital-1.jpg";
import dimg2 from "@assets/digital/digital-2.jpg";
import dimg3 from "@assets/digital/digital-3.jpg";
import dimg4 from "@assets/digital/digital-4.jpg";
import dimg5 from "@assets/digital/digital-5.jpg";
import dimg6 from "@assets/digital/digital-6.jpg";
import dimg7 from "@assets/digital/digital-7.jpg";
import dimg8 from "@assets/digital/digital-8.jpg";
import dimg9 from "@assets/digital/digital-9.jpg";
import dimg10 from "@assets/digital/digital-10.jpg";

// Physical assets
import img1 from "@assets/products/goods-1.jpg";
import img2 from "@assets/products/goods-2.jpg";
import img3 from "@assets/products/goods-3.jpg";
import img4 from "@assets/products/goods-4.jpg";
import img5 from "@assets/products/goods-5.jpg";
import img6 from "@assets/products/goods-6.jpg";
import img7 from "@assets/products/goods-7.jpg";
import img8 from "@assets/products/goods-8.jpg";

const DIGITALGOODS_LIST = [
	{
		assetImg: dimg1,
		name: "Zelda Sword",
		category: "Weapon",
		price: 200.0,
	},
	{
		assetImg: dimg2,
		name: "Romulus Shield",
		category: "Weapon",
		price: 210.0,
	},
	{
		assetImg: dimg3,
		name: "AGS Rifle",
		category: "Weapon",
		price: 220.0,
	},
	{
		assetImg: dimg4,
		name: "Magical Bow",
		category: "Weapon",
		price: 230.0,
	},
	{
		assetImg: dimg5,
		name: "CS Rifle",
		category: "Weapon",
		price: 240.0,
	},
	{
		assetImg: dimg6,
		name: "Spear SS",
		category: "Weapon",
		price: 250.0,
	},
	{
		assetImg: dimg7,
		name: "Minecraft Asset",
		category: "Skin",
		price: 260.0,
	},
	{
		assetImg: dimg8,
		name: "HP +++",
		category: "Items",
		price: 270.0,
	},
	{
		assetImg: dimg9,
		name: "Long Sword",
		category: "Weapon",
		price: 280.0,
	},
	{
		assetImg: dimg10,
		name: "Abyss Gem",
		category: "Magic",
		price: 290.0,
	},
	{
		assetImg: dimg1,
		name: "Zelda Sword",
		category: "Weapon",
		price: 200.0,
	},
	{
		assetImg: dimg2,
		name: "Romulus Shield",
		category: "Weapon",
		price: 210.0,
	},
	{
		assetImg: dimg3,
		name: "AGS Rifle",
		category: "Weapon",
		price: 220.0,
	},
	{
		assetImg: dimg4,
		name: "Magical Bow",
		category: "Weapon",
		price: 230.0,
	},
	{
		assetImg: dimg5,
		name: "CS Rifle",
		category: "Weapon",
		price: 240.0,
	},
];

const PHYSICALGOODS_LIST = [
	{
		assetImg: img1,
		name: "Ryzen Gaming Mouse",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img2,
		name: "Mouse Pad Plus X",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img3,
		name: "Custom Gaming Console",
		category: "Gamingstation",
		price: 250.0,
	},
	{
		assetImg: img4,
		name: "Rock Gaming Heads",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img5,
		name: "Gameboy ADV",
		category: "Gamingstation",
		price: 250.0,
	},
	{
		assetImg: img6,
		name: "Pro Chair R",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img7,
		name: "Xbox Held",
		category: "Gamingstation",
		price: 250.0,
	},
	{
		assetImg: img8,
		name: "Mech Keyboard White - 200hz",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img2,
		name: "Mouse Pad Plus X",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img3,
		name: "Custom Gaming Console",
		category: "Gamingstation",
		price: 250.0,
	},
	{
		assetImg: img4,
		name: "Rock Gaming Heads",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img5,
		name: "Gameboy ADV",
		category: "Gamingstation",
		price: 250.0,
	},
	{
		assetImg: img6,
		name: "Pro Chair R",
		category: "Pro Gaming",
		price: 250.0,
	},
	{
		assetImg: img7,
		name: "Xbox Held",
		category: "Gamingstation",
		price: 250.0,
	},
	{
		assetImg: img8,
		name: "Mech Keyboard White - 200hz",
		category: "Pro Gaming",
		price: 250.0,
	},
];

export {DIGITALGOODS_LIST, PHYSICALGOODS_LIST}