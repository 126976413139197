// components
import React, { useState } from "react";
import PageHeader from "@layout/PageHeader";

// assets
import coinImg from "@assets/coin-img.svg";
import volary from "@assets/volary.svg";

const transactions = [
	{
		date: "09-05-2023",
		event: "Wallet Generation",
		paymentMethod: "-",
		isName: true,
		name: "Robert Clarke",
		amount: "",
		sentFrom: "-",
		sentTo: "-",
	},
	{
		date: "11-02-2023",
		event: "Import",
		paymentMethod: "Credit Card (7256)",
		isName: false,
		name: "",
		amount: "356 Tokens",
		sentFrom: "Atlas Hoover (1A1z...v7Di)",
		sentTo: "-",
	},
	{
		date: "17-11-2022",
		event: "Wallet Generation",
		paymentMethod: "-",
		isName: true,
		name: "Jennifer",
		amount: "",
		sentFrom: "-",
		sentTo: "-",
	},
	{
		date: "17-06-2022",
		event: "Wallet Generation",
		paymentMethod: "-",
		isName: true,
		name: "Bruce Wayne",
		amount: "",
		sentFrom: "-",
		sentTo: "-",
	},
	{
		date: "18-04-2022",
		event: "Transfer",
		paymentMethod: "Credit Card (7256)",
		isName: false,
		name: "",
		amount: "4,983 VLRY",
		sentFrom: "Robert Clarke (45yg...35tg)",
		sentTo: "w3vcttvy3534...g3hg",
	},
	{
		date: "13-09-2021",
		event: "Wallet Generation",
		paymentMethod: "-",
		isName: true,
		name: "Monica Green",
		amount: "",
		sentFrom: "-",
		sentTo: "-",
	},
	{
		date: "27-08-2012",
		event: "Import",
		paymentMethod: "Credit Card (8543)",
		isName: false,
		name: "",
		amount: "1,987 Points",
		sentFrom: "Jeneliya Tree (35tg...3tgg)",
		sentTo: "-",
	},
	{
		date: "25-12-2020",
		event: "Transfer",
		paymentMethod: "Credit Card (5674)",
		isName: false,
		name: "",
		amount: "836 Tokens",
		sentFrom: "Oliver Ken (fsdg...432g)",
		sentTo: "6725r257t6i4r...756r",
	},
	{
		date: "29-05-2020",
		event: "Transfer",
		paymentMethod: "Credit Card (7944)",
		isName: false,
		name: "",
		amount: "108 VLRY",
		sentFrom: "Daymond Dre (5ttg...234t)",
		sentTo: "u635tc7icoi4y...v43r",
	},
	{
		date: "26-08-2019",
		event: "Import",
		paymentMethod: "Credit Card (4622)",
		isName: false,
		name: "",
		amount: "2,936 VLRY",
		sentFrom: "Steven Bon (3ttf...4g3g)",
		sentTo: "-",
	},
];

const Wallet = () => {
	const [isTransferDropdown, setIsTransferDropdown] = useState(false);
	const [isImportDropdown, setIsImportDropdown] = useState(false);
	const toggleTransferDropdown = () => {
		setIsTransferDropdown((prevState) => !prevState);
	};

	const toggleImportDropdown = () => {
		setIsImportDropdown((prevState) => !prevState);
	};

	return (
		<>
			<PageHeader title="Wallet" />
			<div className="row g-3 mb-4 pb-md-2 wallet-row">
				<div className="col-md-4">
					<div className="card">
						<div className="card-body p-lg-4 h-100 d-flex flex-column justify-content-between gap-5">
							<div className="upper-block">
								<div className="d-flex align-items-center justify-content-between gap-2 mb-4 wallet-title">
									<h2 className="text-uppercase">
										Generate Wallet
									</h2>
								</div>
								<div className="form-group mb-4">
									<input
										type="text"
										className="field bg-secondary"
										placeholder="Enter name"
									/>
								</div>
								<div className="d-flex flex-column g-20">
									<div className="info-block d-flex flex-column">
										<p className="fs-16 lh-sm text-white fw-semibold">
											Mnemonic
										</p>
										<p className="fs-16 lh-sm fw-semibold">emerge junge</p>
									</div>
									<div className="info-block d-flex flex-column">
										<p className="fs-16 lh-sm text-white fw-semibold">
											Private Key
										</p>
										<p className="fs-16 lh-sm fw-semibold text-truncate">
											09uhudcbwewuiu984urehdnf
										</p>
									</div>
									<div className="info-block d-flex flex-column">
										<p className="fs-16 lh-sm text-white fw-semibold">
											Wallet Address
										</p>
										<p className="fs-16 lh-sm fw-semibold text-truncate">
											7923ruidhwuebrf943oruo34t0fnwem43u8rinf4e
										</p>
									</div>
								</div>
							</div>
							<button className="btn btn--primary w-100">GENERATE</button>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className="card">
						<div className="card-body p-lg-4 h-100 d-flex flex-column justify-content-between gap-5">
							<div className="upper-block">
								<div className="d-flex align-items-center justify-content-between gap-2 mb-4 wallet-title">
									<h2 className="text-uppercase">
										Import
									</h2>
									<div
										className={`token-dropdown position-relative ${
											isImportDropdown ? "open" : ""
										}`}
									>
										<div
											className="dropdown-text d-inline-flex align-items-center justify-content-between"
											onClick={toggleImportDropdown}
										>
											<div className="d-flex align-items-center g-6">
												<img src={coinImg} alt="Coin" className="avatar-xxs" />
												Tokens
											</div>
											<i className="material-symbols-outlined d-inline-flex fs-24 text-grey arrow">
												arrow_drop_down
											</i>
										</div>
										<div className="dropdown-list">
											<span className="dropdown-link">
												<img src={volary} alt="Coin" className="avatar-xxs" />
												Tokens
											</span>
											<span className="dropdown-link">
												<img src={coinImg} alt="Coin" className="avatar-xxs" />
												Tokens
											</span>
										</div>
									</div>
								</div>
								<div className="form-group position-relative form-box big-inner-text mb-4">
									<input
										type="text"
										className="field bg-secondary"
										placeholder="Enter name"
										value="3,762 TKNS"
									/>
									<span className="inner-text fs-14 lh-sm fw-semibold text-grey position-absolute top-50 translate-middle-y">
										8,536 USD
									</span>
								</div>
								<div className="form-group mb-3">
									<label className="d-block fs-16 lh-sm fw-semibold text-white mb-1">
										Import in (select wallet)
									</label>
									<select className="custom-select">
										<option value="Atlas Hoover">Atlas Hoover</option>
										<option value="Clark Wayne">Clark Wayne</option>
										<option value="Mike Donavan">Mike Donavan</option>
										<option value="Elena Gilbert">Elena Gilbert</option>
									</select>
								</div>
								<div className="form-group">
									<label className="d-block fs-16 lh-sm fw-semibold text-white mb-1">
										Select method of payment
									</label>
									<select className="custom-select">
										<option value="Card ending in 8253 (default)">
											Card ending in 8253 (default)
										</option>
										<option value="Card ending in 7352">
											Card ending in 7352
										</option>
										<option value="Card ending in 6352">
											Card ending in 6352
										</option>
									</select>
								</div>
							</div>
							<button className="btn btn--primary w-100">IMPORT</button>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className="card">
						<div className="card-body p-lg-4 h-100 d-flex flex-column justify-content-between gap-5">
							<div className="upper-block">
								<div className="d-flex align-items-center justify-content-between gap-2 mb-4 wallet-title">
									<h2 className="text-uppercase">
										Transfer
									</h2>
									<div
										className={`token-dropdown position-relative ${
											isTransferDropdown ? "open" : ""
										}`}
									>
										<div
											className="dropdown-text d-inline-flex align-items-center justify-content-between"
											onClick={toggleTransferDropdown}
										>
											<div className="d-flex align-items-center g-6">
												<img src={volary} alt="Coin" className="avatar-xxs" />
												Volary
											</div>
											<i className="material-symbols-outlined d-inline-flex fs-24 text-grey arrow">
												arrow_drop_down
											</i>
										</div>
										<div className="dropdown-list">
											<span className="dropdown-link">
												<img src={coinImg} alt="Coin" className="avatar-xxs" />
												Tokens
											</span>
											<span className="dropdown-link">
												<img src={volary} alt="Coin" className="avatar-xxs" />
												Tokens
											</span>
										</div>
									</div>
								</div>
								<div className="form-group position-relative form-box mb-4">
									<input
										type="text"
										className="field bg-secondary pe-5"
										placeholder="Enter amount in USD"
									/>
									<span className="inner-text fs-14 lh-sm fw-semibold text-grey position-absolute top-50 translate-middle-y">
										VLRY
									</span>
								</div>
								<div className="form-group position-relative form-box overflow-hidden mb-4">
									<input
										type="text"
										className="field bg-secondary pe-5"
										placeholder="Enter receiver's wallet address"
									/>
									<span className="inner-icon fs-14 lh-sm fw-semibold text-grey position-absolute top-50 end-0 translate-middle-y avatar-md h-100 d-inline-flex align-items-center justify-content-center z-1 cursor-pointer">
										<i className="material-symbols-outlined d-inline-flex fs-16 text-white">
											content_copy
										</i>
									</span>
								</div>
								<div className="form-group mb-3">
									<label className="d-block fs-16 lh-sm fw-semibold text-white mb-1">
										Send from (select wallet)
									</label>
									<select className="custom-select">
										<option value="Clark Wayne">Clark Wayne</option>
										<option value="Atlas shield">Atlas shield</option>
										<option value="Mike Donavan">Mike Donavan</option>
										<option value="Elena Gilbert">Elena Gilbert</option>
									</select>
								</div>
								<div className="form-group">
									<label className="d-block fs-16 lh-sm fw-semibold text-white mb-1">
										Select method of payment
									</label>
									<select className="custom-select">
										<option value="Card ending in 8253 (default)">
											Card ending in 8253 (default)
										</option>
										<option value="Card ending in 7352">
											Card ending in 7352
										</option>
										<option value="Card ending in 6352">
											Card ending in 6352
										</option>
									</select>
								</div>
							</div>
							<button className="btn btn--primary w-100">SEND</button>
						</div>
					</div>
				</div>
			</div>
			<div className="card">
				<div className="card-body p-lg-4">
					<h2 className="text-uppercase mb-3 pb-md-1">
						Transactions
					</h2>
					<div className="table-responsive">
						<table className="table m-0">
							<thead>
								<tr>
									<th className="sorting">Date</th>
									<th className="sorting">PI</th>
									<th className="sorting">Method of Payment</th>
									<th className="sorting">Other Details</th>
									<th className="sorting">Imported in / Sent from</th>
									<th className="sorting">Sent to</th>
								</tr>
							</thead>
							<tbody>
								{transactions.map((transaction, index) => (
									<tr key={index}>
										<td>{transaction.date}</td>
										<td className="text-accent">{transaction.event}</td>
										<td>{transaction.paymentMethod}</td>
										<td>
											{transaction.isName ? (
												<div>
													Name (
													<span className="text-grey">{transaction.name}</span>)
												</div>
											) : (
												<div>
													Amount (
													<span className="text-grey">{transaction.amount}</span>)
												</div>
											)}
										</td>
										<td>{transaction.sentFrom}</td>
										<td>{transaction.sentTo}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};

export default Wallet;
