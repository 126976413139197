// styling
import styled from "styled-components/macro";
import theme from "styled-theming";

// components
import Spring from "@components/Spring";
import LeagueHeader from "@components/LeagueHeader";

// hooks
import { useThemeProvider } from "@contexts/themeContext";

// assets
import english_premier from "@assets/clubs/english_premier.webp";

// data placeholder
import esports_standings from "@db/esports_standings";
import EteamScoreRow, { StyledRow } from "@components/EteamScoreRow";
import TournamentsTabs from "./TournamentsTabs";

const TableHeader = styled(StyledRow)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${theme("theme", {
		light: "var(--body)",
		dark: "var(--color-blue5)",
	})};
	color: var(--btn-text) !important;

	&.ltr {
		padding: 0 10px;
	}

	&.rtl {
		padding: 0 10px;
	}

	div {
		background: ${theme("theme", {
			light: "var(--body)",
			dark: "var(--color-blue5)",
		})};
	}

	.points {
		margin-right: 4px;
	}
`;

const EsportsStanding = () => {
	const { direction } = useThemeProvider();
	const tableData = esports_standings.sort((a, b) => b.pts - a.pts);

	return (
		<Spring className="card h-100 d-flex flex-column">
			<TournamentsTabs />
			<div className="card-padded">
				<LeagueHeader
					title={<>eSports Standing</>}
					img={english_premier}
					variant="compact"
				/>
				<div className="d-flex flex-column g-4 mt-25">
					<TableHeader className={`label h6 ${direction}`}>
						<span className="flex-1">Position</span>
						<span>Match Record</span>
					</TableHeader>
					<div className="d-flex flex-column g-1">
						{tableData.map((item, index) => (
							<EteamScoreRow
								key={index}
								data={item}
								index={index}
								variant="league"
							/>
						))}
					</div>
				</div>
			</div>
		</Spring>
	);
};

export default EsportsStanding;
