// styling
import styled from "styled-components/macro";
import styles from "./styles.module.scss";

// components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import SwiperControls from "@ui/SwiperControls";
import { NavLink } from "react-router-dom";

// hooks
import { useEffect, useState } from "react";
import { useThemeProvider } from "@contexts/themeContext";

// utils
import PropTypes from "prop-types";

// assets
import img1 from "@assets/digital/digital-1.jpg";
import img2 from "@assets/digital/digital-2.jpg";
import img3 from "@assets/digital/digital-3.jpg";
import img4 from "@assets/digital/digital-4.jpg";
import img5 from "@assets/digital/digital-5.jpg";
import img6 from "@assets/digital/digital-6.jpg";
import img7 from "@assets/digital/digital-7.jpg";
import img8 from "@assets/digital/digital-8.jpg";
import img9 from "@assets/digital/digital-9.jpg";
import img10 from "@assets/digital/digital-10.jpg";

const AssetList = styled.div`
	display: flex;
	position: relative;

	.swiper {
		padding: 10px 0;
	}
`;

const assets = [
	{
		assetImg: img1,
		title: "Zelda Sword",
		category: "Weapon",
	},
	{
		assetImg: img2,
		title: "Romulus Shield",
		category: "Weapon",
	},
	{
		assetImg: img3,
		title: "AGS Rifle",
		category: "Weapon",
	},
	{
		assetImg: img4,
		title: "Magical Bow",
		category: "Weapon",
	},
	{
		assetImg: img5,
		title: "CS Rifle",
		category: "Weapon",
	},
	{
		assetImg: img6,
		title: "Spear SS",
		category: "Weapon",
	},
	{
		assetImg: img7,
		title: "Minecraft Asset",
		category: "Skin",
	},
	{
		assetImg: img8,
		title: "HP +++",
		category: "Items",
	},
	{
		assetImg: img9,
		title: "Long Sword",
		category: "Weapon",
	},
	{
		assetImg: img10,
		title: "Abyss Gem",
		category: "Magic",
	},
];

const FeaturedAssets = ({ active, setActive, options, innerRef }) => {
	const [swiper, setSwiper] = useState(null);
	const { direction } = useThemeProvider();

	useEffect(() => {
		if (swiper) {
			swiper.changeLanguageDirection(direction);
			swiper.update();
		}
	}, [swiper, direction]);

	return (
		<AssetList ref={innerRef}>
			<Swiper
				className="h-100"
				onSwiper={setSwiper}
				dir={direction}
				slidesPerView={1}
				spaceBetween={24}
				loop={true}
				modules={[Autoplay, Navigation]}
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true,
				}}
				breakpoints={{
					768: {
						slidesPerView: 2,
					},
					1200: {
						slidesPerView: 5,
					},
				}}
			>
				{assets.map((asset, index) => (
					<SwiperSlide key={index}>
						<div className={`${styles.slide} card position-relative`}>
							<img className="cover" src={asset.assetImg} alt={asset.title} />
							<div
								className={`${styles.main} d-flex flex-column h-100 justify-content-end g-8 card-padded`}
							>
								<div className="d-flex align-items-center justify-content-between">
									<NavLink
										className="h3 light"
										to="/product"
										style={{ maxWidth: 178 }}
									>
										{asset.title}
									</NavLink>
								</div>
								<span className="label label--store light h6">
									{asset.category}
								</span>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			<SwiperControls swiper={swiper} />
		</AssetList>
	);
};

FeaturedAssets.propTypes = {
	active: PropTypes.string,
	setActive: PropTypes.func,
	options: PropTypes.array,
	innerRef: PropTypes.any,
};

export default FeaturedAssets;
