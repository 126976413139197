// styling
import styles from "./styles.module.scss";

// components
import ScrollContainer from "@components/ScrollContainer";

// assets
import reply from "@assets/reply-icon.svg";
import like from "@assets/like-icon.svg";
import userImg from "@assets/avatars/5.webp";
import Pagination from "@components/Pagination";

const CommentBox = () => {
	return (
		<>
			<div className="card m-0 h-auto">
				<div className="card-body">
					<div className="inner-scroll-wrapper" style={{ height: "600px" }}>
						<div className="inner-scroll">
							<div className="comment-box border-bottom pt-1 py-4 mb-4">
								<div className="d-flex gap-2 gap-md-3">
									<div className="user-img avatar-md flex-shrink-0 rounded-circle overflow-hidden">
										<img
											src={userImg}
											alt="userImg"
											className="w-100 h-100 object-fit-cover"
										/>
									</div>
									<div className="comment-block flex-grow-1 pt-1">
										<div className="title d-flex align-items-end gap-2 mb-2">
											<h3>Elizabeth Valentine</h3>
											<p className="fs-10 lh-base text-grey">25 mins ago</p>
											<a nohref className="fs-10 lh-base text-grey">
												Report
											</a>
										</div>
										<p className="mb-3 mb-md-4">
											Consectetur adipisicing elit. Dolorum cum velit nostrum.
											Quas cum modi voluptate laudantium dicta unde, facilis
											corporis! Odio voluptatem non deserunt voluptates modi
											maiores reiciendis voluptatibus.
										</p>
										<div className="d-flex align-items-center gap-2">
											<button className="btn btn--primary btn--icon avatar-sm p-0">
												<img src={reply} alt="Reply" className="avatar-xxxs" />
											</button>
											<button className="btn btn--secondary btn--icon avatar-sm p-0">
												<img src={like} alt="Like" className="avatar-xxxs" />
											</button>
											<p className="fs-10 lh-base text-grey">12 Thumbs Up</p>
										</div>
									</div>
								</div>
							</div>
							<div className="comment-box border-bottom pt-1 py-4 mb-4">
								<div className="d-flex gap-2 gap-md-3">
									<div className="user-img avatar-md flex-shrink-0 rounded-circle overflow-hidden">
										<img
											src={userImg}
											alt="userImg"
											className="w-100 h-100 object-fit-cover"
										/>
									</div>
									<div className="comment-block flex-grow-1 pt-1">
										<div className="title d-flex align-items-end gap-2 mb-2">
											<h3>Elizabeth Valentine</h3>
											<p className="fs-10 lh-base text-grey">25 mins ago</p>
											<a nohref className="fs-10 lh-base text-grey">
												Report
											</a>
										</div>
										<p className="mb-3 mb-md-4">
											Consectetur adipisicing elit. Dolorum cum velit nostrum.
											Quas cum modi voluptate laudantium dicta unde, facilis
											corporis! Odio voluptatem non deserunt voluptates modi
											maiores reiciendis voluptatibus.
										</p>
										<div className="d-flex align-items-center gap-2">
											<button className="btn btn--primary btn--icon avatar-sm p-0">
												<img src={reply} alt="Reply" className="avatar-xxxs" />
											</button>
											<button className="btn btn--secondary btn--icon avatar-sm p-0">
												<img src={like} alt="Like" className="avatar-xxxs" />
											</button>
											<p className="fs-10 lh-base text-grey">12 Thumbs Up</p>
										</div>
									</div>
								</div>
								<div className={`${styles.reply_box} reply-box`}>
									<div className="d-flex gap-2 gap-md-3">
										<div className="user-img avatar-md flex-shrink-0 rounded-circle overflow-hidden">
											<img
												src={userImg}
												alt="userImg"
												className="w-100 h-100 object-fit-cover"
											/>
										</div>
										<div className="comment-block flex-grow-1 pt-1">
											<div className="title d-flex align-items-end gap-2 mb-2">
												<h3>Elizabeth Valentine</h3>
												<p className="fs-10 lh-base text-grey">25 mins ago</p>
												<a nohref className="fs-10 lh-base text-grey">
													Report
												</a>
											</div>
											<p className="mb-3 mb-md-4">
												Consectetur adipisicing elit. Dolorum cum velit nostrum.
												Quas cum modi voluptate laudantium dicta unde, facilis
												corporis! Odio voluptatem non deserunt voluptates modi
												maiores reiciendis voluptatibus.
											</p>
											<div className="d-flex align-items-center gap-2">
												<button className="btn btn--primary btn--icon avatar-sm p-0">
													<img
														src={reply}
														alt="Reply"
														className="avatar-xxxs"
													/>
												</button>
												<button className="btn btn--secondary btn--icon avatar-sm p-0">
													<img src={like} alt="Like" className="avatar-xxxs" />
												</button>
												<p className="fs-10 lh-base text-grey">12 Thumbs Up</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="comment-box border-bottom pt-1 py-4 mb-4">
								<div className="d-flex gap-2 gap-md-3">
									<div className="user-img avatar-md flex-shrink-0 rounded-circle overflow-hidden">
										<img
											src={userImg}
											alt="userImg"
											className="w-100 h-100 object-fit-cover"
										/>
									</div>
									<div className="comment-block flex-grow-1 pt-1">
										<div className="title d-flex align-items-end gap-2 mb-2">
											<h3>Elizabeth Valentine</h3>
											<p className="fs-10 lh-base text-grey">25 mins ago</p>
											<a nohref className="fs-10 lh-base text-grey">
												Report
											</a>
										</div>
										<p className="mb-3 mb-md-4">
											Consectetur adipisicing elit. Dolorum cum velit nostrum.
											Quas cum modi voluptate laudantium dicta unde, facilis
											corporis! Odio voluptatem non deserunt voluptates modi
											maiores reiciendis voluptatibus.
										</p>
										<div className="d-flex align-items-center gap-2">
											<button className="btn btn--primary btn--icon avatar-sm p-0">
												<img src={reply} alt="Reply" className="avatar-xxxs" />
											</button>
											<button className="btn btn--secondary btn--icon avatar-sm p-0">
												<img src={like} alt="Like" className="avatar-xxxs" />
											</button>
											<p className="fs-10 lh-base text-grey">12 Thumbs Up</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="pt-3">
						<Pagination />
					</div>
				</div>
			</div>
		</>
	);
};

export default CommentBox;
