// styling
import styles from "./styles.module.scss";

// assets
import shape1 from "@assets/top-left-triangle.svg";
import shape2 from "@assets/bottom-left-triangle.svg";
import cardBg from "@assets/user-profile-img-2.png";
import menuIcon from "@assets/vertical-dot-menu.svg";
import teamProfile1 from "@assets/team-profile-01.png";
import teamProfile2 from "@assets/team-profile-02.png";
import teamProfile3 from "@assets/team-profile-03.png";
import matchIcon from "@assets/match-icon.png";
import steam from "@assets/steam.png";
import playStation from "@assets/playstation.svg";
import xbox from "@assets/xbox.svg";
import monetizationImg01 from "@assets/monetization-img-01.png";
import monetizationImg02 from "@assets/monetization-img-02.png";
import monetizationImg03 from "@assets/monetization-img-03.png";
import shape from "@assets/shape-img.svg";

const matches = [
	{
		isToday: true,
		teamProfile: teamProfile1,
		teamName: "Icebox",
		winRatio: "13 : 11",
		kdaRatio: "24 / 16 / 12",
	},
	{
		isToday: true,
		teamProfile: teamProfile2,
		teamName: "Breeze",
		winRatio: "13 : 11",
		kdaRatio: "21 / 11 / 7",
	},
	{
		isToday: false,
		teamProfile: teamProfile3,
		teamName: "Ascent",
		winRatio: "11 : 13",
		kdaRatio: "29 / 11 / 12",
	},
];

const cards = [
	{
		cardImg: monetizationImg01,
		streamedDate: "7 March 2024",
		duration: "1 hr 23 mins",
	},
	{
		cardImg: monetizationImg02,
		streamedDate: "3 March 2024",
		duration: "1 hr 45 mins",
	},
	{
		cardImg: monetizationImg03,
		streamedDate: "17 March 2024",
		duration: "1 hr 35 mins",
	},
];

const HomeUserProfile = () => {
	return (
		<>
			<div className="row gx-md-5 row-gap-4">
				<div className="col-lg-5">
					<div className="rating-block mb-4 pb-md-2">
						<div className="d-flex align-items-center justify-content-between gap-2 mb-3">
							<p className="fs-16 lh-sm fw-semibold text-white">
								Your Global Rating
							</p>
							<span className="d-inline-flex align-items-center justify-content-end cursor-pointer text-white">
								<i className="material-symbols-outlined d-inline-flex fs-20">
									more_vert
								</i>
							</span>
						</div>
						<div className="card h-auto">
							<div
								className={`${styles.rating_card_body} card-body position-relative`}
							>
								<h2 className="fs-48 lh-sm gradient-text fw-normal text-uppercase position-relative z-1">
									RADIANT 725
								</h2>
								<div className={`${styles.rating_card_triangle_bg}`}>
									<img src={shape} alt="shape" />
								</div>
								<div className={`${styles.rating_card_shape_top}`}>
									<img src={shape1} alt="Triangle" />
								</div>
								<div className={`${styles.rating_card_shape_bottom}`}>
									<img src={shape2} alt="Triangle" />
								</div>
								<div className={`${styles.rating_card_bg} overflow-hidden`}>
									<img
										src={cardBg}
										alt="bg"
										className="w-100 h-100 object-fit-cover"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="ratio-block">
						<p className="fs-16 lh-sm fw-semibold text-white mb-3">
							K/D/A Ratio
						</p>
						<div className="card h-auto">
							<div className="card-body p-md-4">
								<div className="d-flex align-items-center gap-2 mb-3 pb-1">
									<div className={`${styles.ratio_indicator}`}></div>
									<p className="fs-12 lh-sm fw-semibold text-white text-uppercase">
										Today's matches
									</p>
								</div>
								<div className="d-flex flex-column gap-4 mb-3 pb-1">
									{matches.map((match, index) => (
										<div
											key={index}
											className={`gradient-block ${
												match.isToday ? "today" : ""
											}`}
										>
											<div className="d-flex align-items-center justify-content-between gap-2">
												<div className="d-flex align-items-center">
													<div className="avatar-sm overflow-hidden rounded-circle flex-shrink-0 me-2 me-sm-3">
														<img src={match.teamProfile} alt="teamProfile" />
													</div>
													<div className="me-3 me-sm-2 ps-sm-1 pe-1 pe-sm-3">
														<p className="fs-12 lh-sm fw-semibold text-uppercase">
															{match.teamName}
														</p>
														<p className="fs-16 lh-sm text-white fw-semibold text-uppercase">
															{match.winRatio}
														</p>
													</div>
													<div className="">
														<p className="fs-12 lh-sm fw-semibold text-uppercase">
															K / D / A
														</p>
														<p className="fs-16 lh-sm text-white fw-semibold text-uppercase">
															{match.kdaRatio}
														</p>
													</div>
												</div>
												<div className="match-icon">
													<img
														src={matchIcon}
														alt="matchGround"
														className="avatar-sm"
													/>
												</div>
											</div>
										</div>
									))}
								</div>
								<a href="/league-matches" className="d-flex">
									<button className="btn btn--primary w-100">
										View All Matches
									</button>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-7">
					<div className="import-block mb-4 pb-md-2">
						<p className="fs-16 lh-sm fw-semibold text-white mb-3">
							Import your stats from
						</p>
						<div className="d-flex flex-wrap align-items-center g-20">
							<div
								className={`${styles.import_square} d-flex align-items-center justify-content-center bg-primary p-2 text-center`}
							>
								<div className="inner-content text-center">
									<img
										src={steam}
										alt="Steam"
										className="avatar-sm mx-auto mb-2"
									/>
									<p className="fs-16 lh-sm text-white fw-semibold Steam">
										Steam
									</p>
								</div>
							</div>
							<div
								className={`${styles.import_square} d-flex align-items-center justify-content-center bg-primary p-2 text-center`}
							>
								<div className="inner-content text-center">
									<img
										src={playStation}
										alt="PlayStation"
										className="avatar-sm mx-auto mb-2"
									/>
									<p className="fs-16 lh-sm text-white fw-semibold Steam">
										Play Station
									</p>
								</div>
							</div>
							<div
								className={`${styles.import_square} d-flex align-items-center justify-content-center bg-primary p-2 text-center`}
							>
								<div className="inner-content text-center">
									<img
										src={xbox}
										alt="Xbox"
										className="avatar-sm mx-auto mb-2"
									/>
									<p className="fs-16 lh-sm text-white fw-semibold Steam">
										Xbox
									</p>
								</div>
							</div>
							<div
								className={`${styles.import_bordered} d-flex align-items-center justify-content-center p-3 text-center upload-box position-relative`}
							>
								<input
									type="file"
									className="position-absolute start-0 top-0 w-100 h-100 opacity-0 cursor-pointer"
								/>
								<div className="inner-content text-center">
									<p className="fs-16 lh-sm text-accent fw-semibold Steam">
										+Add
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="monetization-block">
						<div className="d-flex align-items-center justify-content-between gap-3 mb-3">
							<p className="fs-16 lh-sm fw-semibold text-white">Monetization</p>
							<p className="fs-14 lh-sm fw-semibold text-green d-inline-flex align-items-center gap-1">
								<i className="material-symbols-outlined d-inline-flex fs-14">
									check_circle
								</i>
								Available
							</p>
						</div>
						<div className="card">
							<div className="card-body p-4 pb-4">
								<div className="row g-3 mb-3">
									{cards.map((card, index) => (
										<div className="col-sm-4" key={index}>
											<div className="card bg-secondary">
												<div className="card-body p-0">
													<div className={`${styles.card_img} overflow-hidden`}>
														<img
															src={card.cardImg}
															alt="cardImg"
															className="w-100 h-100 object-fit-cover"
														/>
													</div>
													<div className="card-content p-2">
														<div className="d-flex align-items-center justify-content-between gap-2">
															<p className="fs-12 lh-sm fw-semibold">
																Streamed on {card.streamedDate}
															</p>
															<p className="fs-12 lh-sm fw-semibold">
																{card.duration}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
								<p className="fs-16 lh-sm fw-semibold mb-1">
									Unlock the potential of your gameplay by monetizing your
									content effortlessly! Share your gaming adventures, engage
									your audience, and earn rewards as you play. From casual
									streams to epic highlights, turn your passion into profit.
									Start your journey to gaming success today!
								</p>
							</div>
							<button className="btn btn--primary w-100 d-flex align-items-center justify-content-end gap-2 gap-md-3">
								Monetize & Earn
								<i className="material-symbols-outlined d-inline-flex fs-28">
									trending_flat
								</i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HomeUserProfile;
