// styling
import styles from "./styles.module.scss";

// components
import Spring from "@components/Spring";
import LazyImage from "@components/LazyImage";

// hooks
import { useThemeProvider } from "@contexts/themeContext";

// assets
import ball from "@assets/ads/ads-2.png";

const MerchSecond = () => {
	const { direction } = useThemeProvider();

	return (
		<Spring className={`${styles.wrapper} card h-100 flex-row`}>
			<div className={`${styles.main} ${styles[direction]}`}>
				<div className="d-flex flex-column g-8">
					<span className="text-12">Official Partner of Romulus</span>
					<h3 className={`${styles.main_title} merch-mobile-subtitle`}>Mechanical Keyboard - Ryze Ver. 4</h3>
				</div>
				<button className="btn btn--primary btn--sm">Learn more</button>
			</div>
			<LazyImage className={styles.media} src={ball} alt="Mechanical Keyboard" />
		</Spring>
	);
};

export default MerchSecond;
