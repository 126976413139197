// styling
import styled from "styled-components/macro";
import styles from "./styles.module.scss";

// components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperControls from "@ui/SwiperControls";

// hooks
import { useEffect, useState } from "react";
import { useThemeProvider } from "@contexts/themeContext";

// utils
import PropTypes from "prop-types";

// assets
import img1 from "@assets/t-shirt-01.png";
import img2 from "@assets/t-shirt-02.png";
import img3 from "@assets/t-shirt-03.png";
import img4 from "@assets/t-shirt-04.png";

const NewsCardsList = styled.div`
	display: flex;
	position: relative;

	.swiper {
		padding: 10px 0;
	}
`;

const newsCards = [
	{
		assetImg: img1,
		title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quia nesciunt.`,
		user: `Vellatrix`,
		date: `December 15th, 2023`,
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
	},
	{
		assetImg: img4,
		title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quia nesciunt.`,
		user: `Vellatrix`,
		date: `December 15th, 2023`,
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
	},
	{
		assetImg: img2,
		title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quia nesciunt.`,
		user: `Vellatrix`,
		date: `December 15th, 2023`,
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
	},
	{
		assetImg: img3,
		title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quia nesciunt.`,
		user: `Vellatrix`,
		date: `December 15th, 2023`,
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
	},
	{
		assetImg: img1,
		title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quia nesciunt.`,
		user: `Vellatrix`,
		date: `December 15th, 2023`,
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
	},
	{
		assetImg: img2,
		title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quia nesciunt.`,
		user: `Vellatrix`,
		date: `December 15th, 2023`,
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
	},
	{
		assetImg: img3,
		title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quia nesciunt.`,
		user: `Vellatrix`,
		date: `December 15th, 2023`,
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
	},
	{
		assetImg: img4,
		title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quia nesciunt.`,
		user: `Vellatrix`,
		date: `December 15th, 2023`,
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
	},
	{
		assetImg: img1,
		title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quia nesciunt.`,
		user: `Vellatrix`,
		date: `December 15th, 2023`,
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
	},
	{
		assetImg: img3,
		title: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quia nesciunt.`,
		user: `Vellatrix`,
		date: `December 15th, 2023`,
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deleniti cum, maiores nesciunt vero eveniet molestias? Inventore cum doloremque ut perferendis a minus ex, molestias consequuntur veniam iusto tempore in.",
	},
];

const NewsSlider = ({ active, setActive, options, innerRef }) => {
	const [swiper, setSwiper] = useState(null);
	const { direction } = useThemeProvider();

	useEffect(() => {
		if (swiper) {
			swiper.changeLanguageDirection(direction);
			swiper.update();
		}
	}, [swiper, direction]);

	return (
		<NewsCardsList ref={innerRef}>
			<Swiper
				className="h-100"
				onSwiper={setSwiper}
				dir={direction}
				slidesPerView={1}
				spaceBetween={24}
				loop={true}
				breakpoints={{
					768: {
						slidesPerView: 2,
					},
					1200: {
						slidesPerView: 3,
					},
					1499: {
						slidesPerView: 4,
					},
					1799: {
						slidesPerView: 5,
					},
				}}
			>
				{newsCards.map((card, index) => (
					<SwiperSlide key={index}>
						<div className="card position-relative">
							<div className="card-body">
								<div
									className={`${styles.card_img} card-img rounded overflow-hidden mb-3`}
								>
									<img
										src={card.assetImg}
										alt={card.title}
										className="w-100 h-100 object-fit-cover"
									/>
								</div>
								<h3 className="line-clamp line-clamp-2 mb-1">{card.title}</h3>
								<p className="fs-12 lh-base text-grey mb-2">
									By <span className="text-accent">{card.user}</span> |{" "}
									{card.date}
								</p>
								<p className="line-clamp line-clamp-3">{card.description}</p>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			<SwiperControls swiper={swiper} />
		</NewsCardsList>
	);
};

NewsSlider.propTypes = {
	active: PropTypes.string,
	setActive: PropTypes.func,
	options: PropTypes.array,
	innerRef: PropTypes.any,
};

export default NewsSlider;
