const MATCHES = [
	{
		day: "Wednesday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1655210113163_GenG_logo_200407-05.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631819669150_fnc-2021-worlds.png",
		team1: "GEN",
		team2: "FNC",
		time: "14:30",
	},
	{
		day: "Thursday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1682322954525_Bilibili_Gaming_logo_20211.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1644501567410_PSG_3.png",
		team1: "BLG",
		team2: "PSG",
		time: "14:30",
	},
	{
		day: "Friday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1655210113163_GenG_logo_200407-05.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631819669150_fnc-2021-worlds.png",
		team1: "GEN",
		team2: "FNC",
		time: "14:30",
	},
	{
		day: "Saturday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1682322954525_Bilibili_Gaming_logo_20211.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1644501567410_PSG_3.png",
		team1: "BLG",
		team2: "PSG",
		time: "14:30",
	},
	{
		day: "Sunday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1655210113163_GenG_logo_200407-05.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631819669150_fnc-2021-worlds.png",
		team1: "GEN",
		team2: "FNC",
		time: "14:30",
	},
	{
		day: "Monday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1682322954525_Bilibili_Gaming_logo_20211.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1644501567410_PSG_3.png",
		team1: "BLG",
		team2: "PSG",
		time: "14:30",
	},
	{
		day: "Tuesday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2FG2-FullonDark.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1704375161752_T1_esports.png",
		team1: "G2",
		team2: "T1",
		time: "14:30",
	},
	{
		day: "Wednesday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1655210113163_GenG_logo_200407-05.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631819669150_fnc-2021-worlds.png",
		team1: "GEN",
		team2: "FNC",
		time: "14:30",
	},
	{
		day: "Thursday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1682322954525_Bilibili_Gaming_logo_20211.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1644501567410_PSG_3.png",
		team1: "BLG",
		team2: "PSG",
		time: "14:30",
	},
	{
		day: "Friday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1655210113163_GenG_logo_200407-05.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631819669150_fnc-2021-worlds.png",
		team1: "GEN",
		team2: "FNC",
		time: "14:30",
	},
	{
		day: "Saturday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1682322954525_Bilibili_Gaming_logo_20211.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1644501567410_PSG_3.png",
		team1: "BLG",
		team2: "PSG",
		time: "14:30",
	},
	{
		day: "Sunday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1655210113163_GenG_logo_200407-05.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631819669150_fnc-2021-worlds.png",
		team1: "GEN",
		team2: "FNC",
		time: "14:30",
	},
	{
		day: "Monday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1682322954525_Bilibili_Gaming_logo_20211.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1644501567410_PSG_3.png",
		team1: "BLG",
		team2: "PSG",
		time: "14:30",
	},
	{
		day: "Tuesday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2FG2-FullonDark.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1704375161752_T1_esports.png",
		team1: "G2",
		team2: "T1",
		time: "14:30",
	},
	{
		day: "Wednesday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1655210113163_GenG_logo_200407-05.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631819669150_fnc-2021-worlds.png",
		team1: "GEN",
		team2: "FNC",
		time: "14:30",
	},
	{
		day: "Thursday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1682322954525_Bilibili_Gaming_logo_20211.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1644501567410_PSG_3.png",
		team1: "BLG",
		team2: "PSG",
		time: "14:30",
	},
	{
		day: "Friday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1655210113163_GenG_logo_200407-05.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631819669150_fnc-2021-worlds.png",
		team1: "GEN",
		team2: "FNC",
		time: "14:30",
	},
	{
		day: "Saturday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1682322954525_Bilibili_Gaming_logo_20211.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1644501567410_PSG_3.png",
		team1: "BLG",
		team2: "PSG",
		time: "14:30",
	},
	{
		day: "Sunday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1655210113163_GenG_logo_200407-05.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631819669150_fnc-2021-worlds.png",
		team1: "GEN",
		team2: "FNC",
		time: "14:30",
	},
	{
		day: "Monday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1682322954525_Bilibili_Gaming_logo_20211.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1644501567410_PSG_3.png",
		team1: "BLG",
		team2: "PSG",
		time: "14:30",
	},
	{
		day: "Tuesday",
		iconteam1:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2FG2-FullonDark.png",
		iconteam2:
			"https://am-a.akamaihd.net/image?resize=72:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1704375161752_T1_esports.png",
		team1: "G2",
		team2: "T1",
		time: "14:30",
	},
];

export default MATCHES;