// styling
import styles from './styles.module.scss';

// components
import {NavLink} from 'react-router-dom';

// utils
import PropTypes from 'prop-types';

import Romulus_Logo_Left from "@assets/logo/Romulus-Logo-Left.svg";

const Logo = ({size = 'md'}) => {
    return (
        <NavLink className={`${styles.logo} ${styles[size]}`} to="/">
            {/* ROMULUS
            <span className={styles.logo_highlight}>
                <span>eSPORTS</span>
            </span> */}
            <img src={Romulus_Logo_Left} alt="Logo" className="logo-main" />
        </NavLink>
    )
}

Logo.propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'xl'])
}

export default Logo