// styling
import styles from "./styles.module.scss";

// assets
import img1 from "@assets/table-team-profile-01.png";
import img2 from "@assets/table-team-profile-02.png";
import img3 from "@assets/table-team-profile-03.png";

const data = [
	{
		teamProfile: img1,
		teamName: "Fox Academy",
		date: "09-05-2024",
	},
	{
		teamProfile: img2,
		teamName: "TSM Academy",
		date: "08-05-2024",
	},
	{
		teamProfile: img1,
		teamName: "Fox Academy",
		date: "07-05-2024",
	},
	{
		teamProfile: img2,
		teamName: "TSM Academy",
		date: "06-05-2024",
	},
	{
		teamProfile: img3,
		teamName: "FlyQuest",
		date: "03-05-2024",
	},
	{
		teamProfile: img1,
		teamName: "Fox Academy",
		date: "02-05-2024",
	},
	{
		teamProfile: img1,
		teamName: "Fox Academy",
		date: "02-05-2024",
	},
	{
		teamProfile: img3,
		teamName: "FlyQuest",
		date: "25-04-2024",
	},
	{
		teamProfile: img2,
		teamName: "TSM Academy",
		date: "23-04-2024",
	},
	{
		teamProfile: img2,
		teamName: "TSM Academy",
		date: "20-04-2014",
	},
];

const Invitations = () => {
	return (
		<>
		<div className="w-100 card card-padded">
			<div className="table-responsive tournament-result">
				<table className="table m-0">
					<thead>
						<tr>
							<th className="sorting">Team</th>
							<th className="sorting">Date Invited</th>
							<th className="sorting" width="250">Actions</th>
						</tr>
					</thead>
					<tbody>
						{data.map((item, index) => (
							<tr key={index}>
								<td className="py-2">
									<div className="d-flex align-items-center gap-1 w-100">
										<img
											src={item.teamProfile}
											alt="teamProfileImg"
											className="avatar-sm flex-shrink-0"
										/>
										<span className="d-inline-flex ms-1">{item.teamName}</span>
									</div>
								</td>
								<td className="py-2 bg-transparent">{item.date}</td>
								<td className="py-2" width="250">
									<div className="d-flex align-items-center">
										<button className="btn btn--primary" type="button">Accept</button>
										<button className="btn btn--outlined border-red text-accent ms-3" type="button">Decline</button>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>	
		</>
	);
};

export default Invitations;
