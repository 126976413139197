// components
import PageHeader from "@layout/PageHeader";
import AppGrid from "@layout/AppGrid";
import ProductDisplay from "@widgets/ProductDisplay";
import ProductDetails from "@widgets/ProductDetails";
import ProductAdditionalInfo from "@widgets/ProductAdditionalInfo";
import StoreSupport from "@widgets/StoreSupport";
import Reviews from "@widgets/Reviews";
import ProductRowCardList from "@widgets/ProductRowCardList";

// styling
import styled from "styled-components/macro";

import Pagination from "@components/Pagination";
import CustomSelect from "@ui/CustomSelect";

// constants
import MERCHANDISING_LIST from '@constants/merchandising_list';
import { Link } from "react-router-dom";

const TODO_OPTIONS = [
	{ value: 'Game', label: 'Game' },
	{ value: 'nft', label: 'NFT' },
	{ value: 'other', label: 'Other' }
]

const widgets = {
	product_display: <ProductDisplay />,
	product_details: <ProductDetails />,
	product_additional: <ProductAdditionalInfo />,
	support: <StoreSupport />,
	reviews: <Reviews standalone />,
	products_list: <ProductRowCardList isSlider />,
};

const MerchandisingList = styled.div`
	.card-img {
		height: 197px;
	}
`;


const Merchandising = () => {
	return (
		<>
			<PageHeader title="Merchandising Store" />
			{/* <AppGrid id="product" widgets={widgets} /> */}
			<MerchandisingList>
				<div className="w-100">
					<div className="row align-items-center">
						<div className="col-md-8 col-lg-9 col-12">&nbsp;</div>
						<div className="col-md-2 col-lg-1 col-3 text-end pe-0">
							<label className="text-white">Sort By:</label>
						</div>
						<div className="col-md-2 col-9">
							<CustomSelect
								options={TODO_OPTIONS}
								placeholder="Select"
								variant="basic"
							/>
						</div>
					</div>
				</div>
				<div className="row position-relative my-3">
					{MERCHANDISING_LIST.map((product, index) => (
						<div className="col-12 Merchandising-List-Col List-Col-2 col-lg-3 col-md-4 mb-3" key={index}>
							<Link to="/merchandising-details">
								<div className="card position-relative">
									<div className="card-body">
										<div
											className={`card-img rounded overflow-hidden mb-3`}
										>
											<img
												src={product.assetImg}
												alt={product.title}
												className="w-100 h-100 object-fit-cover"
											/>
										</div>
										<p className="line-clamp line-clamp-3 mb-3">{product.info}</p>
										<div className="d-flex align-items-center justify-content-between border-bottom border-dark-blue mb-2 pb-2">
											<h4>Team</h4>
											<h4 className="fw-normal">{product.team}</h4>
										</div>
										<div className="d-flex align-items-center justify-content-between border-bottom border-dark-blue mb-2 pb-2">
											<h4>Type</h4>
											<h4 className="fw-normal">{product.type}</h4>
										</div>
										<div className="d-flex align-items-center justify-content-between border-bottom border-dark-blue mb-2 pb-2">
											<h4>Color</h4>
											<h4 className="fw-normal">{product.color}</h4>
										</div>
										<div className="d-flex align-items-center justify-content-between border-bottom border-dark-blue mb-2 pb-2">
											<h4>Fabric</h4>
											<h4 className="fw-normal">{product.fabric}</h4>
										</div>
										<div className="d-flex align-items-center justify-content-between">
											<h4>Year</h4>
											<h4 className="fw-normal">{product.year}</h4>
										</div>
									</div>
								</div>
							</Link>
						</div>
					))}
				</div>
				<div className="w-100">
					<Pagination />
				</div>
			</MerchandisingList>
		</>
	);
};

export default Merchandising;
