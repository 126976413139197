import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from '@contexts/themeContext';
import {ShopProvider} from '@contexts/shopContext';
import {Provider} from 'react-redux';
import store from './app/store';
import { PublicClientApplication } from '@azure/msal-browser';

const root = ReactDOM.createRoot(document.getElementById('root'));
const publicClientApp=new PublicClientApplication({
    auth:{
        clientId:"33a20775-88b7-40dc-8640-554f1cdb50bd",
        authority:"https://login.microsoftonline.com/consumers",
        redirectUri:"https://romulus-app.sandbox.volary.tech/"
    },
})
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider>
                <ShopProvider>
                    <App msalInstance={publicClientApp}/>
                </ShopProvider>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
);
