// components
import Lottie from 'lottie-react';
import Spring from '@components/Spring';

// assets
// import surface119737 from '@assets/shield-loading.json';
import romulusloader from '@assets/romulus-loader.json';

const LoadingScreen = () => {
    return (
        <Spring className="d-flex align-items-center justify-content-center flex-1 w-100 h-100">
            <Lottie animationData={romulusloader} />
        </Spring>
    )
}

export default LoadingScreen