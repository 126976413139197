// styling
import styles from "./styles.module.scss";

// assets
import userProfile from "@assets/user-profile-img-2.png";
import twitch from "@assets/twitch-icon.svg";
import img1 from "@assets/table-team-profile-01.png";
import img2 from "@assets/table-team-profile-02.png";
import img3 from "@assets/table-team-profile-03.png";

const data = [
	{
		teamProfile: img1,
		teamName: "Fox Academy",
		startDate: "Jul 2018",
		endDate: "Jan 2019",
		duration: "5mo 17d",
		notes: "-",
	},
	{
		teamProfile: img1,
		teamName: "Fox Academy",
		startDate: "Jan 2019",
		endDate: "Mar 2019",
		duration: "2mo 9d",
		notes: "Inactive",
	},
	{
		teamProfile: img2,
		teamName: "TSM Academy",
		startDate: "Apr 2019",
		endDate: "May 2020",
		duration: "1yr 1mo",
		notes: "-",
	},
	{
		teamProfile: img2,
		teamName: "TSM Academy",
		startDate: "Aug 2019",
		endDate: "Dec 2019",
		duration: "4mo",
		notes: "-",
	},
	{
		teamProfile: img2,
		teamName: "TSM Academy",
		startDate: "May 2019",
		endDate: "Sep 2022",
		duration: "2yr 3mo",
		notes: "-",
	},
	{
		teamProfile: img3,
		teamName: "FlyQuest",
		startDate: "Dec 2022",
		endDate: "Dec 2023",
		duration: "1yr 6d",
		notes: "-",
	},
	{
		teamProfile: img3,
		teamName: "FlyQuest",
		startDate: "Dec 2022",
		endDate: "Present",
		duration: "4mo 20d",
		notes: "-",
	},
];

const OverviewUserProfile = () => {
	return (
		<>
			<div className="row row-gap-4 gx-lg-5">
				<div className="col-md-7">
					<p className="fs-16 lh-sm text-white fw-semibold mb-3">
						Alexander, a strategic mastermind in League of Legends, excels in
						mid-lane dominance and outsmarting opponents with unrivaled tactical
						prowess
					</p>
					<div className="row g-3 mb-4">
						<div className="col-sm-6 col-xl-4">
							<button className="btn btn--outlined primary w-100 gap-1 px-1">
								<i className="material-symbols-outlined d-inline-flex fs-18">
									sports_esports
								</i>
								View Your Data
							</button>
						</div>
						<div className="col-sm-6 col-xl-4">
							<button className="btn btn--outlined primary w-100 gap-1 px-1">
								<i className="material-symbols-outlined d-inline-flex fs-18">
									download
								</i>
								Download Your Data
							</button>
						</div>
						<div className="col-md-12 col-xl-4">
							<button className="btn btn--outlined secondary w-100 gap-1 px-1">
								<i className="material-symbols-outlined d-inline-flex fs-18">
									delete
								</i>
								Delete Your Data
							</button>
						</div>
					</div>
					<div className="w-100 card h-auto card-padded">
						<div className="table-responsive">
							<table className="table m-0">
								<thead>
									<tr>
										<th className="sorting">Team</th>
										<th className="sorting">Start</th>
										<th className="sorting">End</th>
										<th className="sorting">Duration</th>
										<th className="sorting">Notes</th>
									</tr>
								</thead>
								<tbody>
									{data.map((item, index) => (
										<tr key={index}>
											<td className="py-2">
												<div className="d-flex align-items-center gap-1">
													<img
														src={item.teamProfile}
														alt="teamProfileImg"
														className="avatar-sm"
													/>
													{item.teamName}
												</div>
											</td>
											<td className="py-2">{item.startDate}</td>
											<td className="py-2">{item.endDate}</td>
											<td className="py-2">{item.duration}</td>
											<td className="py-2">{item.notes}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="col-md-5">
					<div className="card h-auto">
						<div className="card-body p-0">
							<div className={`${styles.card_img} overflow-hidden`}>
								<img
									src={userProfile}
									alt="userProfile"
									className="w-100 h-100 object-fit-cover"
								/>
							</div>
							<div className="p-3 bg-secondary text-center">
								<p className="fs-16 lh-sm text-white fw-semibold text-uppercase">
									Rise Atlantians
								</p>
							</div>
							<div className="p-4">
								<div className="row row-gap-3 mb-4">
									<div className="col-4">
										<p className="fs-16 lh-sm fw-semibold">Real Name</p>
									</div>
									<div className="col-8">
										<p className="fs-16 lh-sm text-white fw-semibold">
											Alexander Brookes
										</p>
									</div>
									<div className="col-4">
										<p className="fs-16 lh-sm fw-semibold">Country of Birth</p>
									</div>
									<div className="col-8">
										<p className="fs-16 lh-sm text-white fw-semibold">
											America
										</p>
									</div>
									<div className="col-4">
										<p className="fs-16 lh-sm fw-semibold">Birthday</p>
									</div>
									<div className="col-8">
										<p className="fs-16 lh-sm text-white fw-semibold">
											21 Feb 1998
										</p>
									</div>
									<div className="col-4">
										<p className="fs-16 lh-sm fw-semibold">Residency</p>
									</div>
									<div className="col-8">
										<p className="fs-16 lh-sm text-white fw-semibold">
											North America
										</p>
									</div>
								</div>
								<div className="socials d-flex flex-wrap gap-3">
									<div className={`${styles.card_social} bg-secondary`}>
										<img src={twitch} alt="Twitch" />
									</div>
									<div className={`${styles.card_social} bg-secondary`}>
										<i className="icon-instagram d-inline-flex fs-28 lh-1 text-white"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OverviewUserProfile;
