// components
import React, { useState } from "react";
import PageHeader from "@layout/PageHeader";
import HomeUserProfile from "@components/HomeUserProfile";
import OverviewUserProfile from "@components/OverviewUserProfile";
import TournamentResultProfile from "@components/TournamentResultProfile";
import Invitations from "@components/Invitations";
import MatchHistoryProfile from "@components/MatchHistoryProfile";

// assets
import userProfile from "@assets/user-profile-img-2.png";
import silverCoin from "@assets/coin-img.png";
import gameEvent from "@assets/game-event.png";
import gameTeam from "@assets/game-team.png";
import arrowBg from "@assets/arrow-bg.svg";
import coinImg from "@assets/coin-img.svg";
import walletBg from "@assets/wallet-card-bg.png";
import { Link } from "react-router-dom";

const UserProfile = () => {
	const [activeTab, setActiveTab] = useState("tab1");
	return (
		<>
			<PageHeader title="User Profile" />
			<div className="page-wrapper mt-4 pt-2">
				<div className="card user-profile-card card-overflow h-auto mb-3">
					<div className="card-body py-md-0 p-3 p-md-4 pt-0">
						<div className="row row-gap-2 align-items-center">
							<div className="col-md-7 col-lg-6">
								<div className="profile-details">
									<div className="d-flex flex-wrap align-items-end gap-3">
										<div className="profile-img d-inline-flex align-items-center justify-content-center position-relative">
											<div className="profile-avatar flex-shrink-0 overflow-hidden d-inline-flex align-items-center justify-content-center position-relative">
												<img
													src={userProfile}
													alt="userProfile"
													className="w-100 h-100 object-fit-cover"
												/>
											</div>
											<div className="change-profile avatar-sm d-inline-flex align-items-center justify-content-center btn-primary-bg border-0 rounded-circle overflow-hidden position-absolute cursor-pointer z-1">
												<input
													type="file"
													className="position-absolute start-0 top-0 w-100 h-100 opacity-0 cursor-pointer z-1"
												/>
												<i className="material-symbols-outlined d-inline-flex fs-20 text-white">
													photo_camera
												</i>
											</div>
										</div>
										<div className="inner-details flex-grow-1 ps-md-1 pb-3">
											<div className="d-flex align-items-center justify-content-between gap-2 mb-3">
												<div className="d-flex align-items-center gap-2">
													<h2 className="fs-24 fw-normal text-uppercase me-1">
														Rise Atlantians
													</h2>
													<p className="fs-14 fw-semibold d-inline-flex align-items-center gap-1">
														<img
															src={silverCoin}
															alt="coinImg"
															className="avatar-xxxs"
														/>
														Silver
													</p>
												</div>
												<span className="d-inline-flex align-items-center justify-content-end cursor-pointer text-white">
													<i className="material-symbols-outlined d-inline-flex fs-20">
														more_vert
													</i>
												</span>
											</div>
											<div className="progress-block">
												<div className="progress-bar mb-1">
													<div
														className="progress"
														style={{ width: "56%" }}
													></div>
												</div>
												<div className="d-flex justify-content-between gap-1">
													<p className="fs-16 lh-sm fw-semibold text-white">
														XP
													</p>
													<p className="fs-16 lh-sm fw-semibold text-white">
														590/1000
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-5 col-lg-6">
								<div className="d-flex align-items-center justify-content-end">
									<div className="stats-list text-center d-flex align-items-center">
										<div className="stats-item">
											<p className="fs-16 lh-sm fw-semibold text-white mb-1">
												62.11%
											</p>
											<p className="fs-12 lh-sm fw-semibold text-uppercase">
												WIN RATIO
											</p>
										</div>
										<div className="stats-item">
											<p className="fs-16 lh-sm fw-semibold text-white mb-1">
												1.14
											</p>
											<p className="fs-12 lh-sm fw-semibold text-uppercase">
												K/D RATIO
											</p>
										</div>
										<div className="stats-item">
											<p className="fs-16 lh-sm fw-semibold text-white mb-1">
												138.2
											</p>
											<p className="fs-12 lh-sm fw-semibold text-uppercase">
												DMG/ROUND
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row g-3 pt-1 mb-4 pb-md-2">
					<div className="col-lg-4">
						<div className="card wallet-card">
							<div className="card-body p-0 d-flex flex-column justify-content-between h-100">
								<div className="card-bg-img w-100 overflow-hidden position-relative">
									<img
										src={walletBg}
										alt="walletCoin"
										className="w-100 h-100 object-fit-cover"
									/>
								</div>
								<div className="p-3 p-md-4 pb-md-0 pb-0">
									<div className="row row-gap-3">
										<div className="col-4">
											<p className="fs-16 lh-sm fw-semibold">Wallet</p>
										</div>
										<div className="col-8 text-end">
											<p className="fs-16 lh-sm text-white fw-semibold">
												6,328 Volary &nbsp;
												<span className="text-grey">(9,627 USD)</span>
											</p>
										</div>
										<div className="col-4">
											<p className="fs-16 lh-sm fw-semibold">Points</p>
										</div>
										<div className="col-8 text-end">
											<p className="fs-16 lh-sm text-white fw-semibold">
												2,834
											</p>
										</div>
										<div className="col-4">
											<p className="fs-16 lh-sm fw-semibold">Tokens</p>
										</div>
										<div className="col-8 text-end">
											<p className="fs-16 lh-sm text-white fw-semibold d-inline-flex align-items-center g-6">
												<img src={coinImg} alt="Coin" />
												72,352
											</p>
										</div>
									</div>
								</div>
								<div className="p-3 p-md-4 pt-md-3 pt-3">
									<a href="/wallet" className="d-flex">
										<button className="btn btn--primary w-100">
											TOKENOMICS
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="card schedule-card position-relative">
							<div className="card-bg-arrow">
								<img src={arrowBg} alt="arrowBg" />
							</div>
							<div className="card-body p-0 position-relative z-1">
								<div className="row row-gap-3 h-100">
									<div className="col-sm-5 col-lg-4">
										<div className="card-bg position-relative w-100 h-100">
											<img
												src={gameEvent}
												alt="scheduleImg"
												className="w-100 h-100 object-fit-cover"
											/>
										</div>
									</div>
									<div className="col-sm-7 col-lg-8">
										<div className="schedule-card-body position-relative h-100 d-flex flex-column justify-content-between">
											<p className="fs-16 lh-sm fw-semibold mb-2 pb-1">
												Get ready for the next big event! New match starts in...
											</p>
											<div className="timer-block pt-2 mb-3 pb-1">
												<h2 className="fs-48 lh-sm gradient-text fw-normal">
													3 DAYS
												</h2>
												<div className="d-flex flex-wrap align-items-end gap-2">
													<p className="d-flex align-items-end fs-16 fw-semibold text-uppercase gap-1">
														<span className="d-inline-flex fs-20 text-white">
															15
														</span>
														hrs
													</p>
													<p className="d-flex align-items-end fs-16 fw-semibold text-uppercase gap-1">
														<span className="d-inline-flex fs-20 text-white">
															32
														</span>
														mins
													</p>
													<p className="d-flex align-items-end fs-16 fw-semibold text-uppercase gap-1">
														<span className="d-inline-flex fs-20 text-white">
															45
														</span>
														secs
													</p>
												</div>
											</div>
											<button className="btn btn--primary w-100 px-1">
												VIEW FULL SCHEDULE
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="card team-card">
							<div className="card-body position-relative d-flex align-items-end p-4 pt-5">
								<div className="card-bg-img">
									<img
										src={gameTeam}
										alt="teamImg"
										className="w-100 h-100 object-fit-cover"
									/>
								</div>
								<div className="card-content position-relative z-1 w-100">
									<p className="fs-16 lh-sm fw-semibold mb-3">
										Join the action and create your own gaming team for epic
										tournaments today
									</p>
									<Link to="/create-team">
										<button className="btn btn--primary w-100">
											+ CREATE NEW TEAM
										</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="tabs-block">
					<div className="profile-tabs mb-4">
						<ul className="tab-list d-flex align-items-center text-nowrap">
							<li>
								<span
									className={`d-inline-block tab-link ${activeTab === "tab1" ? "active" : ""
										}`}
									onClick={() => setActiveTab("tab1")}
								>
									Home
								</span>
							</li>
							<li>
								<span
									className={`d-inline-block tab-link ${activeTab === "tab2" ? "active" : ""
										}`}
									onClick={() => setActiveTab("tab2")}
								>
									Overview
								</span>
							</li>
							<li>
								<span
									className={`d-inline-block tab-link ${activeTab === "tab3" ? "active" : ""
										}`}
									onClick={() => setActiveTab("tab3")}
								>
									Tournament Results
								</span>
							</li>
							<li>
								<span
									className={`d-inline-block tab-link ${activeTab === "tab4" ? "active" : ""
										}`}
									onClick={() => setActiveTab("tab4")}
								>
									Invitations
								</span>
							</li>
						</ul>
					</div>
					<div className="tab-content">
						{activeTab === "tab1" && <HomeUserProfile />}
						{activeTab === "tab2" && <OverviewUserProfile />}
						{activeTab === "tab3" && <TournamentResultProfile />}
						{activeTab === "tab4" && <Invitations />}
					</div>
				</div>
			</div>
		</>
	);
};

export default UserProfile;
