// components
import PageHeader from "@layout/PageHeader";
import Merch from "@widgets/Merch";
import MerchSecond from "@widgets/MerchSecond";
import FeaturedAssets from "@components/FeaturedAssets";
import Merchandising from "@components/Merchandising";
import Products from "@components/Products";
import { Link } from "react-router-dom";

const Shop = () => {
	return (
		<>
			<PageHeader title="Shop" />
			<div className="row row-gap-4 mb-3">
				<div className="col-md-4 mmb-3">
					<div className="card h-auto">
						<div className="card-body">
							<h3 className="mb-2">Wallet</h3>
							<div className="mb-3">
								<div className="row row-gap-2">
									<div className="col-sm-6">
										<h5>
											<span className="text-20">204.3482</span> USD
										</h5>
									</div>
									<div className="col-sm-6">
										<h5 className="text-sm-end">
											<span className="text-20">4</span> VLRY
										</h5>
									</div>
								</div>
							</div>
							<div className="pt-3 border-top">
								<div className="row row-gap-2">
									<div className="col-sm-6">
										<Link to="/wallet">
											<button className="btn btn--outlined border-red text-accent w-100">Create</button>
										</Link>
									</div>
									<div className="col-sm-6">
										<Link to="/wallet">
											<button className="btn btn--primary w-100">Import</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4 ad-block mmb-3">
					<Merch />
				</div>
				<div className="col-md-4 ad-block mmb-3">
					<MerchSecond />
				</div>
				<div className="col-md-12">
					<div className="asset-section mb-3">
						<h3 className="mb-2">Featured Assets</h3>
						<FeaturedAssets />
					</div>
					<div className="merchandising-section">
						<h3 className="mb-2 d-flex align-items-center justify-content-between">
							<span>Merchandising</span>
							<Link to="/merchandising">
								<button className="btn btn--outlined border-red text-accent btn--sm">View all</button>
							</Link>
						</h3>
						<Merchandising />
					</div>
				</div>
			</div>
			<div className="product-section">
				<h3 className="mb-2 d-flex align-items-center justify-content-between">
					<span>Products</span>
					<button className="btn btn--outlined border-red text-accent btn--sm">View all</button>
				</h3>
				<Products />
			</div>
		</>
	);
};

export default Shop;
