// TournamentContext.js
import React, { createContext, useContext, useState } from 'react';
import tournamentStaticData from '@assets/mockdata/tournaments';

// Initialize context
const TournamentContext = createContext();

export const useTournamentContext = () => useContext(TournamentContext);
// Provider component
export const TournamentProvider = ({ children }) => {
  const [tournaments, setTournaments] = useState(tournamentStaticData);

  const addTournament = (newTournament) => {
    setTournaments([...tournaments, newTournament]);
  };

  const editTournament = (newTournament, index) => {
    tournaments[index] = newTournament;
    setTournaments([...tournaments]);
  };

  const deleteTournament = (index) => {
    const updatedTournaments = [...tournaments];
    updatedTournaments.splice(index, 1);
    setTournaments(updatedTournaments);
  };

  return (
    <TournamentContext.Provider value={{ tournaments, addTournament, editTournament, deleteTournament }}>
      {children}
    </TournamentContext.Provider>
  );
};
