// components
import PageHeader from "@layout/PageHeader";
import Pagination from "@components/Pagination";
import CustomSelect from "@ui/CustomSelect";

// constants
import { DIGITALGOODS_LIST } from '@constants/goods_list';
import CustomRating from "@ui/CustomRating";
import { Link } from "react-router-dom";

const TODO_OPTIONS = [
	{ value: 'Game', label: 'Game' },
	{ value: 'nft', label: 'NFT' },
	{ value: 'other', label: 'Other' }
]

const Digital = () => {
	return (
		<>
			<PageHeader title="Digital Goods" />
			<div className="w-100">
				<div className="row align-items-center">
					<div className="col-md-8 col-lg-9 col-12">&nbsp;</div>
					<div className="col-md-2 col-lg-1 col-3 text-end pe-0">
						<label className="text-white">Sort By:</label>
					</div>
					<div className="col-md-2 col-9">
						<CustomSelect
							options={TODO_OPTIONS}
							placeholder="Select"
							variant="basic"
						/>
					</div>
				</div>
			</div>
			<div className="row position-relative my-3">
				{DIGITALGOODS_LIST.map((product, index) => (
					<div className="col-12 List-Col-2 col-lg-3 col-md-4 mb-3" key={index}>
						<Link to="/digital-goods-details">
							<div className="card position-relative">
								<div className="card-body">
									<div
										className={`card-img rounded overflow-hidden mb-3`}
									>
										<img
											src={product.assetImg}
											alt={product.title}
											className="w-auto h-100 object-fit-contain mx-auto"
										/>
									</div>
									<h3 className="text-break text-uppercase mb-1">
										{product.name}
									</h3>
									<div className="d-flex align-items-center gap-2 mb-3">
										<p>{product.category}</p>
										<CustomRating />
									</div>
									<div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
										<h3>$ {product.price}</h3>
										<button className="btn btn--primary btn--sm">
											Add to Cart
										</button>
									</div>
								</div>
							</div>
						</Link>
					</div>
				))}
			</div>
			<div className="w-100">
				<Pagination />
			</div>
		</>
	);
};

export default Digital;
