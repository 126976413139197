// styling
import styles from "./styles.module.scss";

const Pagination = () => {
	return (
		<>
			<ul className="pagination-list d-flex align-items-center justify-content-end gap-2">
				<li>
					<button className="btn btn--primary btn--icon avatar-sm p-0">
						<i class="icon-chevron-left"></i>
					</button>
				</li>
				<li>
					<button className="btn btn--outlined btn--icon avatar-sm p-0 active">
						1
					</button>
				</li>
				<li>
					<button className="btn btn--outlined btn--icon avatar-sm p-0">
						2
					</button>
				</li>
				<li>
					<button className="btn btn--outlined btn--icon avatar-sm p-0">
						3
					</button>
				</li>
				<li>
					<button className="btn btn--outlined btn--icon avatar-sm p-0">
						...
					</button>
				</li>
				<li>
					<button className="btn btn--outlined btn--icon avatar-sm p-0">
						8
					</button>
				</li>
				<li>
					<button className="btn btn--primary btn--icon avatar-sm p-0">
						<i class="icon-chevron-right"></i>
					</button>
				</li>
			</ul>
		</>
	);
};

export default Pagination;
